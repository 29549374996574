<div style="display: flex; justify-content: space-between; align-items: center; color: #762BFF;">
  <h2 mat-dialog-title>{{"Video" | translate}}</h2>
  <button mat-icon-button color="primary" style="margin: 0 0 16px; color: #762BFF;" (click)="dismiss()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content *ngIf="!selectedVideo">
  <div style="padding: 1em 1.2em;">
    <div>
      <div *ngFor="let video of videos" (click)="selectVideo(video)">
        <div class="manual enabled">
          {{video.name | translate}}
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-content *ngIf="selectedVideo">
  <div class="controls-container">
    <button class="custom-button action" style="width: fit-content"
            (click)="selectedVideo = false">{{"Indietro" | translate}}</button>
  </div>
  <iframe class="ratio-inner" [src]="videoUrl" allow="fullscreen"
          style="border: none; width: 100%; height: 50vh"></iframe>
</mat-dialog-content>
