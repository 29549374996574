import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {AuthService} from '../../_services/auth.service';
import {NeithServiceProviderService} from '../../core/providers/neith-service-provider';
import {TokenStorageService} from '../../_services/token-storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FacebookLoginProvider, SocialAuthService} from 'angularx-social-login';

declare var AppleID;
declare var google;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  showLoginAlert = false;
  showRegisterAlert = false;
  showRegisterErrorAlert = false;
  isRegisteringForDemo = false;
  isLoginFailed = false;

  hasNeithAIActive: boolean = false;
  user: any = {};

  firstname = new FormControl('');
  lastname = new FormControl('');
  email = new FormControl('', [Validators.required, Validators.email]);
  phone = new FormControl('');
  password = new FormControl('', [Validators.required]);
  privacy = new FormControl('');

  errorMessage = '';

  campaign = '';
  medium = '';
  source = '';

  constructor(private authService: AuthService,
              private neithService: NeithServiceProviderService,
              private tokenStorage: TokenStorageService,
              private socialAuthService: SocialAuthService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.utm_campaign) {
        this.campaign = params.utm_campaign;
      }
      if (params.utm_medium) {
        this.medium = params.utm_medium;
      }
      if (params.utm_source) {
        this.source = params.utm_source;
      }
    });
  }

  register() {
    if (!this.privacy.value || this.firstname.invalid || this.lastname.invalid || this.email.invalid || this.password.invalid || this.phone.invalid) {
      this.showRegisterAlert = true;
      return;
    }
    let credentials = {
      'email': this.email.value,
      'password': this.password.value,
      'firstname': this.firstname.value,
      'surname': this.lastname.value,
      'phone': this.phone.value,
      'driving_school_neith': 4646
    };
    this.authService.register(credentials).subscribe((response) => {
      let heruBody = {
        'email': this.email.value,
        'firstname': this.firstname.value,
        'lastname': this.lastname.value,
        'phone': this.phone.value,
        'type': 'demo'
      };
      this.neithService.activateHeruDemo(heruBody).subscribe(() => {
        let body = {
          'email': this.email.value,
          'phone': this.phone.value,
          'firstname': this.firstname.value,
          'lastname': this.lastname.value,
          'type': 'heru',
          'date': new Date().toLocaleDateString(),
          'campaign': this.campaign,
          'medium': this.medium,
          'source': this.source
        };
        this.neithService.saveDemoUserOnCsv(body).subscribe(result => {
          this.onSubmit();
        });
      });
    }, error => {
      this.showRegisterErrorAlert = true;
      this.showRegisterAlert = false;
    });
  }

  onSubmit(): void {
    if (this.email.invalid || this.password.invalid) {
      this.showLoginAlert = true;
      return;
    }

    let credentials = {'email': this.email.value, 'password': this.password.value};
    this.authService.login(credentials).subscribe(
      data => {
        this.tokenStorage.saveToken(data.access_token);
        this.tokenStorage.saveRefreshToken(data.refresh_token);
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
        this.showRegisterErrorAlert = false;
        this.showLoginAlert = false;
        this.showRegisterAlert = false;
      },
      () => {
        this.getUser();
      }
    );
  }

  private getUser() {
    this.neithService.getUser().subscribe(
      data => {
        this.user = data[0];
        this.tokenStorage.saveUser(data[0]);

        this.tokenStorage.getUser().then(user => {
          this.hasNeithAIActive = user.has_neith_ai_active;
          if (this.user.has_neith_gpt || this.user.has_neith_gpt_demo) {
            this.redirectNeithGPT();
          } else {
            this.redirectLanding();
          }
        });
      },
      err => {
      }
    );
  }

  redirectNeithGPT() {
    this.router.navigate(['/heru']);
  }

  redirectLanding() {
    window.location.href = 'https://guidaevai.com/insegnante-virtuale/';
  }

  changeFormType(isRegisteringForDemo) {
    this.isRegisteringForDemo = isRegisteringForDemo;
    this.showLoginAlert = false;
    this.showRegisterAlert = false;
    this.showRegisterErrorAlert = false;
    this.isLoginFailed = false;
    if (this.isRegisteringForDemo) {
      this.firstname.setValidators([Validators.required]);
      this.lastname.setValidators([Validators.required]);
      this.privacy.setValidators([Validators.required]);
      this.phone.setValidators([Validators.required]);
    } else {
      this.firstname.clearValidators();
      this.lastname.clearValidators();
      this.privacy.clearValidators();
      this.phone.clearValidators();
    }
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.socialAuthService.authState.subscribe((user) => {
      let credentials = {
        facebookId: user.id,
        email: user.email,
        password: user.id,
        firstname: user.firstName,
        lastname: user.lastName
      };
      this.authService.loginFacebook(credentials).subscribe(
        data => {
          this.tokenStorage.saveToken(data.access_token);
          this.tokenStorage.saveRefreshToken(data.refresh_token);
        },
        err => {
          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
        },
        () => {
          this.getUser();
        }
      );
    });
  }

  public async signInWithApple() {
    try {
      AppleID.auth.init({
        clientId: 'com.bokapp.QuizPatente-.neith',
        scope: 'name email',
        redirectURI: 'https://insegnantevirtuale.guidaevai.com/login',
        state: 'init',
        usePopup: true
      });
      const data = await AppleID.auth.signIn();
      this.authService.loginApple(data.authorization.code).subscribe(
        data => {
          this.tokenStorage.saveToken(data.access_token);
          this.tokenStorage.saveRefreshToken(data.refresh_token);
        },
        err => {
          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
        },
        () => {
          this.getUser();
        }
      );
    } catch (err) {
      this.isLoginFailed = true;
    }
  }

}
