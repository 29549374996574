import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {TokenStorageService} from './token-storage.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private tokenStorage: TokenStorageService, private router: Router) {
  }

  canActivate() {
    let isLoggedIn = !!this.tokenStorage.getToken();
    if (isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/login']);
    }
    return false;
  }

}
