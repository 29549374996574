<div style="display: flex; justify-content: space-between; align-items: center; color: #762BFF;">
  <h2 mat-dialog-title>{{"Benvenuto" | translate}}</h2>
  <button mat-icon-button color="primary" (click)="dismiss()" style="margin: 0 0 16px; color: #762BFF;">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <video style="width: 100%; height: 70vh;" controls>
    <source src="https://app.guidaevai.com/media/neith_intro_ai.mp4" type="video/mp4">
  </video>
</mat-dialog-content>
