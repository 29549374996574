import {Component, OnInit} from '@angular/core';
import {NeithServiceProviderService} from '../../core/providers/neith-service-provider';
import {MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-superior-videos-dialog',
  templateUrl: './superior-videos-dialog.component.html',
  styleUrls: ['./superior-videos-dialog.component.scss']
})
export class SuperiorVideosDialogComponent implements OnInit {

  selectedVideo = false;
  videoUrl: SafeResourceUrl;
  videos: { name: string, video_url: string }[] = [];

  constructor(private neithServiceProvider: NeithServiceProviderService,
              private dialogRef: MatDialogRef<SuperiorVideosDialogComponent>,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.getVideos();
  }

  getVideos() {
    this.neithServiceProvider.getSuperiorVideos().subscribe(response => {
      JSON.parse(response).forEach(element => {
        this.videos.push({name: element.name, video_url: element.video_url});
      });
    });
  }

  selectVideo(video) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(video.video_url);
    this.selectedVideo = true;
  }

  dismiss() {
    this.dialogRef.close();
  }

}
