import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {NeithServiceProviderService} from '../../core/providers/neith-service-provider';
import {Router} from '@angular/router';

@Component({
  selector: 'app-heru-demo-dialog',
  templateUrl: './heru-demo-dialog.component.html',
  styleUrls: ['./heru-demo-dialog.component.scss']
})
export class HeruDemoDialogComponent implements OnInit {

  disableActivate = false;

  constructor(private dialogRef: MatDialogRef<HeruDemoDialogComponent>,
              private neithService: NeithServiceProviderService,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  dismiss() {
    this.dialogRef.close();
  }

  activateDemo() {
    this.disableActivate = true;
    this.neithService.getUser().subscribe(response => {
      const user = response[0];
      let heruBody = {
        'email': user.email,
        'firstname': user.firstname,
        'lastname': user.surname,
        'phone': user.phone,
        'type': 'demo'
      };
      this.neithService.activateHeruDemo(heruBody).subscribe(() => {
        let body = {
          'email': user.email,
          'phone': user.phone,
          'firstname': user.firstname,
          'lastname': user.surname,
          'type': 'heru',
          'date': new Date().toLocaleDateString()
        };
        this.neithService.saveDemoUserOnCsv(body).subscribe(result => {
          this.router.navigate(['/heru']);
          this.dismiss();
        });
      });
    });
  }

}
