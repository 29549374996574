<div style="display: flex; flex-direction: column; height: 100%">
  <div class="container">
    <div class="section left">
      <div style="display: flex; flex-direction: column; margin: 1em; align-items: center; row-gap: 2em">
        <img src="../../../assets/img/ka_icon_active.svg" alt="" width="200">
      </div>
    </div>
    <div class="section">
      <div class="sub-section-top">
        <form>
          <div style="display: flex; justify-content: center">
          <img src="../../../assets/img/heru.png" alt="Neith" height="100px" width="150px">
          </div>
          <div *ngIf="!isRegisteringForDemo" class="disclaimer">Tutta la potenza di Heru per il tuo studio<br>Fai il login e provalo GRATIS, dopo 7 giorni paghi solo €9,90!</div>
          <div *ngIf="isRegisteringForDemo" class="disclaimer">Tutta la potenza di Heru per il tuo studio<br>Registrati e provalo GRATIS, dopo 7 giorni paghi solo €9,90!</div>
          <input type="text" placeholder="Nome" [formControl]="firstname" name="firstname" *ngIf="isRegisteringForDemo">
          <input type="text" placeholder="Cognome" [formControl]="lastname" name="lastname"
                 *ngIf="isRegisteringForDemo">
          <input type="email" placeholder="Email" required [formControl]="email" name="email">
          <input type="tel" placeholder="Cellulare" required [formControl]="phone" name="phone"
                 *ngIf="isRegisteringForDemo">
          <input type="password" placeholder="Password" required [formControl]="password" name="password">
          <div style="display: flex; justify-content: end" *ngIf="!isRegisteringForDemo">
            <a href="https://app.guidaevai.com/password_reset/" target="_blank"
               style="text-decoration: none;color: #642EFF;font-weight: 500;">Password dimenticata?</a>
          </div>
          <div style="display: flex; justify-content: end; cursor: pointer" *ngIf="!isRegisteringForDemo"
               (click)="changeFormType(true)">
            <span style="text-decoration: none;color: #642EFF;font-weight: 500;">Non hai un account? Registrati e prova gratis!</span>
          </div>
          <div style="display: flex; justify-content: end; cursor: pointer" *ngIf="isRegisteringForDemo"
               (click)="changeFormType(false)">
            <span style="text-decoration: none;color: #642EFF;font-weight: 500;">Già registrato? Fai il login</span>
          </div>
          <div *ngIf="isRegisteringForDemo" style="color: #1C1C1C;display: flex;align-items: center;column-gap: 12px;">
            <input type="checkbox" id="privacy" name="privacy" [formControl]="privacy">
            <label for="privacy">Ho preso visione dell'<a href="https://guidaevai.com/privacy-policy/"
                                                          style="text-decoration: none;color: #642EFF;">informativa</a>
              e
              presto il consenso al trattamento dei miei dati per
              la creazione dell’account</label><br>
          </div>
          <button type="submit" (click)="onSubmit()" *ngIf="!isRegisteringForDemo">Login</button>
          <button type="submit" (click)="register()" *ngIf="isRegisteringForDemo">Registrati</button>
          <div class="danger-alert" *ngIf="isLoginFailed">
            Email e/o password errati
          </div>
          <div class="danger-alert" *ngIf="showLoginAlert">
            Compila tutti i campi per effettuare il login
          </div>
          <div class="danger-alert" *ngIf="showRegisterAlert">
            Compila tutti i campi e accetta l'informativa per registrarti
          </div>
          <div class="danger-alert" *ngIf="showRegisterErrorAlert">
            Credenziali già esistenti
          </div>
          <div style="display: flex; justify-content: center; align-items: center; margin-top: 16px"
               *ngIf="!isRegisteringForDemo">
            <div style="width: 100%; height: 1px; background: #c9c9c9;"></div>
            <div style="width: 100%; display: flex; justify-content: center; color: #c9c9c9">Oppure</div>
            <div style="width: 100%; height: 1px; background: #c9c9c9;"></div>
          </div>
          <div class="other-logins-container" *ngIf="!isRegisteringForDemo">
            <div class="facebook" (click)="signInWithFB()"><img src="../../../assets/img/icons/facebook.svg" alt=""
                                                                width="24"/> Continua con
              Facebook
            </div>
            <div class="apple" (click)="signInWithApple()"><img src="../../../assets/img/icons/apple-logo-white.svg"
                                                                alt=""
                                                                width="24"/> Accedi con Apple
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
