<div style="display: flex; justify-content: space-between; align-items: center; color: #762BFF;">
  <h2 mat-dialog-title>{{"Seleziona un capitolo" | translate}}</h2>
  <button mat-icon-button color="primary" (click)="dismiss()" style="margin: 0 0 16px; color: #762BFF;">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div *ngIf="!selectedArgument" style="padding: 1em 1.2em;">
    <div *ngIf="hasNeithDemo && !hasNeithAIActive">
      <div *ngFor="let argument of arguments, let i = index">
        <div class="manual enabled" (click)="selectArgument(argument)" *ngIf="[0,1,2].indexOf(i) > -1">
          <img *ngIf="argument.thumb" [src]="argument.thumb.image" width="36" alt="">
          {{argument.name | translate}}
        </div>
        <div class="manual disabled" *ngIf="[0,1,2].indexOf(i) == -1" (click)="showDemoDialog()">
          <img *ngIf="argument.thumb" [src]="argument.thumb.image" width="36" alt="">
          {{argument.name | translate}}
        </div>
      </div>
    </div>
    <div *ngIf="hasNeithAIActive">
      <div *ngFor="let argument of arguments">
        <div style="display: flex; align-items: center; column-gap: 8px">
          <div class="manual enabled" (click)="selectArgument(argument)">
            <img *ngIf="argument.thumb" [src]="argument.thumb.image" width="36" alt="">
            {{argument.name | translate}}
          </div>
          <button class="custom-button action" style="width: fit-content; margin-top: 20px;" *ngIf="chatLicenseType !== 11" (click)="selectQuiz(argument)">{{"Quiz" | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedArgument" style="padding: 1em 1.2em;">
    <div class="controls-container">
      <button class="custom-button action" style="width: fit-content"
              (click)="selectedArgument = null">{{"Indietro" | translate}}</button>
      <button class="custom-button action" style="width: fit-content"
              (click)="teachArgument()" *ngIf="hasNeithAIActive">{{"Spiega tutto il capitolo" | translate}}</button>
    </div>
    <div *ngIf="hasNeithDemo && !hasNeithAIActive">
      <div *ngFor="let manual of manuals, let i = index">
        <div class="manual enabled" (click)="selectManual(manual, i)" *ngIf="[0,1,2,3,4,5,6,7,8,9].indexOf(i) > -1">
          <img [src]="manual.image_url" *ngIf="manual.image_url" alt="" width="50">
          {{manual.title | translate}}
        </div>
        <div class="manual disabled" *ngIf="[0,1,2,3,4,5,6,7,8,9].indexOf(i) == -1" (click)="showDemoDialog()">
          <img [src]="manual.image_url" *ngIf="manual.image_url" alt="" width="50">
          {{manual.title | translate}}
        </div>
      </div>
    </div>
    <div *ngIf="hasNeithAIActive">
      <div *ngFor="let manual of manuals, let i = index">
        <div class="manual enabled" (click)="selectManual(manual, i)">
          <img [src]="manual.image_url" *ngIf="manual.image_url" alt="" width="50">
          {{manual.title | translate}}
          <img src="assets/img/icons/correct.svg" alt="" *ngIf="completedManuals.indexOf(manual.id) > -1" width="20"/>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
