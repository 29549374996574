import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-demo-dialog',
  templateUrl: './demo-dialog.component.html',
  styleUrls: ['./demo-dialog.component.scss']
})
export class DemoDialogComponent implements OnInit {

  safeUrl: SafeResourceUrl = null;

  constructor(private sanitizer: DomSanitizer, private dialogRef: MatDialogRef<DemoDialogComponent>) {
  }

  ngOnInit(): void {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/sypnc55fLZw');
  }

  dismiss() {
    this.dialogRef.close();
  }

}
