import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

const AUTH_API = 'https://app.guidaevai.com/o/token/';
const API = 'https://app.guidaevai.com/api/';
const CLIENT_ID = 'BKKBC52kzSLlNQaow7LdwNdSqfyn4eQQzP2c0HYr';
const CLIENT_SECRET = 'fXaHLH8RmDLMdmu1kZTc8rybU2bTjSLFZxQwowppQ75ctRAERPKpCu2O3otQEqX0pyMjtUfeuhB9FJQdqjxrhcVoJXUz0ISg1PfgZy8D5ll6PqR80xSPzs4aOANvmiDf';

const httpOptionsAuth = {
  headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
};

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {
  }

  login(credentials): Observable<any> {
    let body = 'client_id=' + CLIENT_ID
      + '&client_secret=' + CLIENT_SECRET
      + '&grant_type=' + 'password'
      + '&password=' + credentials.password
      + '&username=' + credentials.email;
    return this.http.post(AUTH_API, body, httpOptionsAuth);
  }

  refreshToken(token: string) {
    let body = 'client_id=' + CLIENT_ID
      + '&client_secret=' + CLIENT_SECRET
      + '&grant_type=' + 'refresh_token'
      + '&refresh_token=' + token;
    return this.http.post(AUTH_API, body, httpOptionsAuth);
  }

  loginFacebook(credentials): Observable<any> {
    let body = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      facebook_id: credentials.facebookId,
      email: credentials.email,
      password: credentials.password,
      first_name: credentials.firstname,
      last_name: credentials.lastname
    };
    return this.http.post(API + 'login-facebook/', body, httpOptions);
  }

  loginApple(token): Observable<any> {
    let body = {
      token: token,
    };
    return this.http.post(API + 'login-apple/', body, httpOptions);
  }

  register(credentials): Observable<any> {
    return this.http.post(API + 'users/', {
      firstname: credentials.firstname,
      surname: credentials.surname,
      is_guest: false,
      was_guest: true,
      email: credentials.email,
      phone: credentials.phone,
      password: credentials.password,
      accepted_privacy: true,
      driving_school_neith: credentials.driving_school_neith,
      has_neith_demo: credentials.has_neith_demo
    }, httpOptions);
  }

}
