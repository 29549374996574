<h2 mat-dialog-title>Vuoi attivare la demo di Heru per 7 giorni?</h2>

<mat-dialog-actions style="justify-content: space-between">
  <button class="mat-raised-button" (click)="dismiss()"
          style="box-shadow: none;background: #eeeeee; border: none; padding: 0.8em 1.5em; border-radius: 4px;">Chiudi
  </button>
  <button class="mat-raised-button mat-primary" (click)="activateDemo()" [disabled]="disableActivate"
          style="box-shadow: none; background: #642EFF; border: none; padding: 0.8em 1.5em; border-radius: 4px;">Attiva
  </button>
</mat-dialog-actions>
