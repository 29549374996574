import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BuyDialogComponent} from '../buy-dialog/buy-dialog.component';

@Component({
  selector: 'app-images-dialog',
  templateUrl: './images-dialog.component.html',
  styleUrls: ['./images-dialog.component.scss']
})
export class ImagesDialogComponent implements OnInit {

  manualId: number;
  isVideo: boolean = false;

  videoUrl: String;
  selectedImage: String = null;
  selectedImageIndex: number = null;
  imageId: number = null;

  images: {
    id: number;
    name: String;
    url: String;
  }[] = [];

  galleryImages: {
    id: number;
    name: String;
    url: String;
  }[] = [];

  hasNeithDemo: boolean = true;
  hasNeithAIActive: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ImagesDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.manualId = this.data.manualId;
    this.images = this.data.images;
    this.galleryImages = this.data.images;
    this.isVideo = this.data.isVideo;
    this.videoUrl = this.data.videoUrl;
    this.hasNeithDemo = this.data.hasNeithDemo;
    this.hasNeithAIActive = this.data.hasNeithAIActive;

    this.images = this.images.filter(image => {
      if (image.url.indexOf('DS_Store') < 0) {
        return image;
      }
    });
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('80%', '80%');
  }

  selectImage(index, imageId, url) {
    if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].indexOf(index) < 0 && !this.hasNeithAIActive) {
      this.showDemoDialog();
      return;
    }
    this.imageId = imageId;
    this.selectedImageIndex = index;
    this.selectedImage = url;
  }

  returnImage() {
    this.dialogRef.close({imageIndex: this.selectedImageIndex, imageId: this.imageId});
  }

  nextImage() {
    let nextIndex = this.selectedImageIndex + 1;
    if (nextIndex < this.images.length) {
      this.selectedImageIndex = nextIndex;
      this.imageId = this.images[this.selectedImageIndex].id;
      this.selectedImage = this.images[this.selectedImageIndex].url;
    }
  }

  previousImage() {
    let prevIndex = this.selectedImageIndex - 1;
    if (prevIndex >= 0) {
      this.selectedImageIndex = prevIndex;
      this.imageId = this.images[this.selectedImageIndex].id;
      this.selectedImage = this.images[this.selectedImageIndex].url;
    }
  }

  dismiss() {
    this.dialogRef.close();
  }

  showDemoDialog() {
    const dialog = this.dialog.open(BuyDialogComponent);
  }

  searchImage(e) {
    const searchString = e.target.value;
    if (searchString !== '') {
      this.galleryImages = this.images.filter(image => image.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    } else {
      this.galleryImages = this.images;
    }
  }

}
