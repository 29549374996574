import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-buy-dialog',
  templateUrl: './buy-dialog.component.html',
  styleUrls: ['./buy-dialog.component.scss']
})
export class BuyDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<BuyDialogComponent>
  ) {
  }

  ngOnInit(): void {
  }

  dismiss() {
    this.dialogRef.close();
  }

  goToShop() {
    window.location.href = 'https://guidaevai.com/prodotto/pacchetto-neith-insegnante-virtuale/';
  }

}
