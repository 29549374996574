<div style="width: 100%; height: 100%; display: flex; flex-direction: column;">
  <mat-toolbar class="custom-header">
    <img src="../../../assets/img/heru.svg" alt="Neith">
    <div class="username-container">
      <div class="username">Heru</div>
      <div class="username-status" *ngIf="!isLoading">{{"Online" | translate}}</div>
      <div class="username-status" *ngIf="isLoading">{{"Sta scrivendo..." | translate}}</div>
    </div>
  </mat-toolbar>
  <div class="container">
    <div #scrollScene class="scene">
      <div *ngFor="let message of messages, let i = index">
        <div style="display: flex; justify-content: center; align-items: center; margin-top: 16px; margin-bottom: 16px;"
             *ngIf="(message.date && i == 0) || (message.date && message.date !== messages[i-1].date)">
          <div style="width: 100%; height: 1px; background: #c9c9c9;"></div>
          <div
            style="width: 100%; display: flex; justify-content: center; color: #c9c9c9">{{message.date == today ? "Oggi" : (message.date == yesterday ? "Ieri" : message.date) }}</div>
          <div style="width: 100%; height: 1px; background: #c9c9c9;"></div>
        </div>
        <div class="message-container" [ngClass]="{'from-them': message.fromAi, 'from-me': !message.fromAi}">
          <p [ngClass]="{'from-them': message.fromAi, 'from-me': !message.fromAi}" [innerHTML]="message.text"></p>
          <div class="message-buttons-container">
            <button class="custom-button action" (click)="listenToLesson(message)"
                    *ngIf="message.audioUrl && !message.isAudioPlaying">
              {{"Spiegazione vocale" | translate}}
            </button>
            <button class="custom-button action" (click)="stopAudio(message)" *ngIf="message.isAudioPlaying">
              {{"Interrompi spiegazione vocale" | translate}}
            </button>
          </div>
        </div>
      </div>
      <p class="from-them loading" *ngIf="isLoading">
          <span class="dots-container">
            <span class="dot1"> </span>
            <span class="dot2"></span>
            <span class="dot3"></span>
          </span>
      </p>
    </div>
  </div>
</div>

<mat-toolbar class="message-box">
  <input type="text" class="text-input" (keyup.enter)="sendPrompt()" [(ngModel)]="promptString"
         [placeholder]="'Chiedimi qualcosa' | translate">
  <button mat-icon-button color="primary" (click)="sendPrompt()">
    <mat-icon>send</mat-icon>
  </button>
</mat-toolbar>
