import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-demo-intro-dialog',
  templateUrl: './demo-intro-dialog.component.html',
  styleUrls: ['./demo-intro-dialog.component.scss']
})
export class DemoIntroDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DemoIntroDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
  }

  ngOnInit(): void {
  }

  dismiss() {
    this.dialogRef.close();
  }

}
