<div style="display: flex; justify-content: space-between; align-items: center; color: #762BFF;">
  <h2 mat-dialog-title
      *ngIf="!isVideo">{{manualId ? ("Altre immagini" | translate) : ("Seleziona un'immagine"| translate)}}</h2>
  <h2 mat-dialog-title *ngIf="isVideo">{{"Video della lezione" | translate}}</h2>
  <button mat-icon-button color="primary" (click)="dismiss()" style="margin: 0 0 16px; color: #762BFF;">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content *ngIf="!isVideo && !selectedImage">
  <input type="text" class="text-input" [placeholder]="'Cerca' | translate" (keyup.enter)="searchImage($event)" *ngIf="hasNeithAIActive">
  <div class="gallery">
    <div *ngFor="let image of galleryImages, let i = index" (click)="selectImage(i, image.id, image.url)"
         class="gallery-item">
      <div class="hover-layer"></div>
      <div class="hover-layer-disable"
           *ngIf="!hasNeithAIActive && hasNeithDemo && [0,1,2,3,4,5,6,7,8,9].indexOf(i) < 0"></div>
      <img [src]="image.url" alt="" style="object-fit: scale-down; width: 100px; height: 100px">
      <div *ngIf="!manualId" style="margin: 0.6em">{{image.name | translate}}</div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-content *ngIf="selectedImage">
  <div class="controls-container">
    <button class="custom-button action" style="width: fit-content"
            (click)="selectedImage = null">{{"Indietro" | translate}}</button>
    <button class="custom-button action" style="width: fit-content" (click)="previousImage()"
            *ngIf="hasNeithAIActive">{{"<"}}</button>
    <button class="custom-button action" style="width: fit-content" (click)="nextImage()"
            *ngIf="hasNeithAIActive">{{">"}}</button>
    <button class="custom-button action" style="width: fit-content" (click)="returnImage()"
            *ngIf="manualId == null">{{"Seleziona" | translate}}</button>
  </div>
  <img [src]="selectedImage" alt="" class="selected-image">
</mat-dialog-content>
<mat-dialog-content *ngIf="isVideo">
  <iframe class="ratio-inner" [src]="videoUrl" allow="fullscreen"
          style="border: none; width: 100%; height: 50vh"></iframe>
</mat-dialog-content>
