import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NeithServiceProviderService {

  baseURL: String = '';
  aiBaseURL: String = '';

  constructor(public http: HttpClient) {
    this.baseURL = 'https://app.guidaevai.com/';
    this.aiBaseURL = 'https://ai.guidaevai.com/';
  }

  public getUser(): Observable<any> {
    return this.http.get(this.baseURL + `api/users/`);
  }

  public getUserAbTestVersion(userID): Observable<any> {
    return this.http.get(this.baseURL + `api/users/${userID}/get_user_test_version/`);
  }

  public updateUser(userID, body): Observable<any> {
    return this.http.patch(this.baseURL + `api/users/${userID}/`, body);
  }

  public saveDemoUserOnCsv(body): Observable<any> {
    return this.http.post(this.baseURL + 'save-demo-user-on-csv/', body);
  }

  public activateHeruDemo(body): Observable<any>{
    return this.http.post(this.baseURL + 'activate-user-neith-gpt-zapier/fdnuD3nfe20cde23nu23eEFEWdn23n9DeGH32tf/', body);
  }

  public getAIConfig(drivingSchoolId): Observable<any> {
    let drivingSchool = '';
    if (drivingSchoolId) {
      drivingSchool = `?driving_school=${drivingSchoolId}`;
    }
    return this.http.get(this.baseURL + `api/ai-configs/${drivingSchool}`);
  }

  public getArgumentsFromLicenseType(license_type, drivingSchoolId): Observable<any> {
    let drivingSchool = '';
    if (drivingSchoolId) {
      drivingSchool = `&driving_school=${drivingSchoolId}`;
    }
    return this.http.get(this.baseURL + `api/ai-arguments/?license_type=${license_type}${drivingSchool}`);
  }

  public getManuals(argument: number): Observable<any> {
    return this.http.get(this.baseURL + `api/manual-titles/?argument=${argument}`);
  }

  public getSuperiorManuals(argument: number): Observable<any> {
    return this.http.post(this.aiBaseURL + `get-superior-argument-manual/`, {argument_id: argument});
  }

  public createChat(userId: number): Observable<any> {
    let body = {user_id: userId};
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai/create_chat/`, body);
  }

  public setIntroVideoShown(chatId: number): Observable<any> {
    return this.http.post<any>('https://ai.guidaevai.com/api/chat-ai/update_chat_intro_video_shown/', {'chat_id': chatId});
  }

  public getChatMessages(userId: number, chatId: number): Observable<any> {
    return this.http.get<any>(this.aiBaseURL + `api/chat-messages/?u=${userId}&c=${chatId}`);
  }

  public updateChatLanguage(chatId: number, language: number): Observable<any> {
    let body = {chat_id: chatId, language: language};
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai/update_chat_language/`, body);
  }

  public sendAiPrompt(prompt: String, chatId: number, licenseType: number): Observable<any> {
    let body = {prompt: prompt, chat_id: chatId, license_type: licenseType};
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai/completion/`, body);
  }

  public startTeaching(argument: number, manual: number, chatId: number, manualId: number, manualTitle: String, manualImage: String, isTeaching: boolean): Observable<any> {
    let body = {
      argument: argument,
      manual: manual,
      chat_id: chatId,
      manual_id: manualId,
      manual_title: manualTitle,
      manual_image: manualImage,
      is_teaching: isTeaching
    };
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai/start_teaching/`, body);
  }

  public startTeachingSuperior(argument: number, manual: number, chatId: number, isTeaching: boolean): Observable<any> {
    let body = {
      argument_id: argument,
      manual_index: manual,
      chat_id: chatId,
      is_teaching: isTeaching
    };
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai/start_teaching_superior/`, body);
  }

  public explainImage(imageIndex: number, chatId: number): Observable<any> {
    let body = {image_index: imageIndex, chat_id: chatId};
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai/explain_image/`, body);
  }

  public sendAiFallbackPrompt(prompt: String, aiText: String): Observable<any> {
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai/fall_back_completion/`, {prompt: prompt, ai: aiText});
  }

  public sendAiHello(name: String, aiName: String): Observable<any> {
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai/send_hello/`, {name: name, ai_name: aiName});
  }

  public translate(text: String, language: number): Observable<any> {
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai/translate/`, {text: text, language: language});
  }

  public getQuizzesFromManual(manualId, language?: string): Observable<any> {
    let headers = {};
    if (language) {
      headers = {headers: new HttpHeaders().set('Accept-Language', language)};
    }
    return this.http.get<any>(`https://app.guidaevai.com/api/manual-quizzes/?license_type=11&manual=${manualId}`, headers);
  }

  public getSuperiorQuizzes(licenseType, argumentId, language?: string): Observable<any> {
    let headers = {};
    if (language) {
      headers = {headers: new HttpHeaders().set('Accept-Language', language)};
    }
    return this.http.get<any>(`https://app.guidaevai.com/api/manual-quizzes/?license_type=${licenseType}&argument=${argumentId}`, headers);
  }

  public getManualOtherImages(manualId): Observable<any> {
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai/get_other_images/`, {manual_id: manualId});
  }

  public getManualVideo(manualId): Observable<any> {
    return this.http.get<any>(this.baseURL + `api/deepening-videos/get_manual_video/?manual_id=${manualId}`);
  }

  public getLastStats(chatId): Observable<any> {
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai-stats/get_last_stats/`, {chat_id: chatId});
  }

  public createStats(chatId: number, studiedManuals: number[], correctQuizzes: number[], incorrectQuizzes: number[]): Observable<any> {
    let body = {
      chat_id: chatId,
      studied_manuals: studiedManuals,
      correct_quizzes: correctQuizzes,
      incorrect_quizzes: incorrectQuizzes
    };
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai-stats/create_stats/`, body);
  }

  public getNeithDrivingSchools(city): Observable<any> {
    return this.http.get(this.baseURL + `api/neith-driving-schools/?city=${city}`);
  }

  public sendNeithRequest(userId, drivingSchoolId): Observable<any> {
    return this.http.post(this.baseURL + `api/users/${userId}/send_neith_request/`, {driving_school_neith: drivingSchoolId});
  }

  public updateStats(chatStatsId: number, studiedManuals: number[], correctQuizzes: number[], incorrectQuizzes: number[]): Observable<any> {
    let body = {
      chat_stats_id: chatStatsId,
      studied_manuals: studiedManuals,
      correct_quizzes: correctQuizzes,
      incorrect_quizzes: incorrectQuizzes
    };
    return this.http.post<any>(this.aiBaseURL + `api/chat-ai-stats/update_stats/`, body);
  }

  public getAudioStream(audioUrl: string): Observable<ArrayBuffer> {
    return this.http.get(audioUrl, {responseType: 'arraybuffer'});
  }

  public getCompletedManuals(userId): Observable<any> {
    return this.http.get(this.aiBaseURL + `api/user-completed-manuals/get_completed_manuals/?u=${userId}`);
  }

  public setCompletedManual(userId, manualId): Observable<any> {
    return this.http.post(this.aiBaseURL + `api/user-completed-manuals/set_completed_manual/`, {
      'user_id': userId,
      'manual_id': manualId
    });
  }

  public getSuperiorVideos(): Observable<any> {
    return this.http.get(this.baseURL + `api/deepening-videos/get_superior_video/`);
  }

  public retrieveTeacherChat(userId: number): Observable<any> {
    let body = {user_id: userId};
    return this.http.post<any>(this.aiBaseURL + `api/teacher-chat/create_chat/`, body);
  }

  public getTeacherChatMessages(userId: number, chatId: number): Observable<any> {
    return this.http.get<any>(this.aiBaseURL + `api/teacher-chat-messages/?u=${userId}&c=${chatId}`);
  }

  public sendTeacherPrompt(prompt: string, chatId: number): Observable<any>{
    let body = {prompt: prompt, chat_id: chatId};
    return this.http.post<any>(this.aiBaseURL + `api/teacher-chat/completion/`, body);
  }

}
