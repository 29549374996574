import {Component, ElementRef, OnInit, AfterViewChecked, ViewChild, QueryList, ViewChildren, HostListener} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {of, Subscription} from 'rxjs';
import {AIConfig, Argument} from '../../core/models';
import {NeithServiceProviderService} from '../../core/providers/neith-service-provider';
import {MatDialog} from '@angular/material/dialog';
import {ImagesDialogComponent} from '../images-dialog/images-dialog.component';
import {ArgumentsDialogComponent} from '../arguments-dialog/arguments-dialog.component';
import {TokenStorageService} from 'src/app/_services/token-storage.service';
import {TranslateService} from '@ngx-translate/core';
import {DemoDialogComponent} from '../demo-dialog/demo-dialog.component';
import {Router} from '@angular/router';
import {DemoIntroDialogComponent} from '../demo-intro-dialog/demo-intro-dialog.component';
import {SuperiorVideosDialogComponent} from '../superior-videos-dialog/superior-videos-dialog.component';
import {BuyDialogComponent} from '../buy-dialog/buy-dialog.component';
import {HeruDemoDialogComponent} from '../heru-demo-dialog/heru-demo-dialog.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewChecked {

  @ViewChild('scrollScene') private scrollScene: ElementRef;
  @ViewChildren('lesson') components: QueryList<any>;

  LICENSE_TYPE_B = 11;
  LICENSE_TYPE_C_CE = 15;
  LICENSE_TYPE_C1_C1E = 14;
  LICENSE_TYPE_D_DE = 17;
  LICENSE_TYPE_D1_D1E = 16;
  LICENSE_TYPE_C1_C1E_NOT_PROFESSIONAL = 18;

  options = {'weekday': 'short', 'month': 'short', 'day': '2-digit'};
  today: String = new Date().toLocaleString('it-IT', this.options);
  yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date).toLocaleString('it-IT', this.options);

  langCodes: string[] = ['it', 'en', 'es', 'fr', 'de', 'zh', 'ru', 'ro', 'uk'];
  selectedLang = 'it';
  hasHeru: boolean = false;

  videoSentences: string[] = ['Hai ancora dubbi su questo argomento? Guarda il video', 'Vuoi avere altre informazioni su questo argomento? Guarda il video', 'Sei sicuro di sapere tutto su questo argomento? Ti consiglio di guardare il video', 'Diventa il numero uno su questo argomento, guarda il video'];

  aiName: String = 'Salvo';
  userId: number = 1;
  chatId: number;
  chatLicenseType: number;
  introVideoShown: boolean;

  messages: {
    fromAi: boolean;
    text: String;
    imageIndex?: number;
    imageUrl?: String;
    isArgumentName?: boolean;
    isTeaching?: boolean;
    manualId?: number;
    manualName?: String;
    quizShown?: boolean;
    trueQuizShown?: boolean;
    falseQuizShown?: boolean;
    hasQuizzes?: boolean;
    isError?: boolean;
    isQuiz?: boolean;
    isVideo?: boolean;
    isReview?: boolean;
    selectedArgument?: number;
    manualIndex?: number;
    quizId?: number;
    answer?: boolean;
    userAnswer?: boolean;
    userHasAnswered?: boolean;
    isAudioPlaying?: boolean;
    audioUrl?: String;
    date?: String;
    isLanguageSelector?: boolean;
    hideMessage?: boolean;
    isQuizText?: boolean;
    isOldMessage?: boolean;
  }[] = [];

  sub: Subscription = null;

  isTeaching: boolean = false;

  manualIndex: number = 0;
  selectedArgument: number = 0;

  isResume: boolean = false;
  isReview: boolean = false;

  selectedImageIndex: number = -1;
  selectedImageId: number = -1;

  currentLessonIndex: number = -1;
  currentLessonMessageIndex: number = -1;
  currentLessonText: String = '';
  currentLessonTitle: string = '';

  currentVideoMessageIndex: number = -1;

  audioLesson = null;

  promptString: String = '';
  lastPrompt: String = '';
  lastResponse: String = '';

  // loading: Loading
  isLoading: boolean = false;

  currentStats = null;
  lastStats: {
    manual: String;
    manual_title: String;
    correct: number;
    incorrect: number;
    not_answered_count: number
    total: String;
    errors_percentage: number;
    argument_position: String;
    manual_index: String;
  }[] = null;

  statsMessageIndex: number = -1;
  lessonReviewMessagesIndexes: number[] = [];

  studiedManuals = [];
  correctQuizzes = [];
  incorrectQuizzes = [];

  manuals: {
    id?: number;
    title: String;
    image_url?: String;
  }[] = [];

  images: {
    id: number;
    name: String;
    url: String;
  }[] = [];

  otherImages: {
    manualId: number;
    url: String
  }[] = [];

  videos: {
    manualId: number;
    url: SafeResourceUrl
  }[] = [];

  arguments: Argument[] = [];
  completedManuals: number[] = [];
  aiConfig: AIConfig;
  user: any;

  quizListOpened: boolean = false;
  showOtherActions: boolean = false;

  hasNeithDemo: boolean = false;
  hasNeithAIActive: boolean = false;
  neithDemoMessagesNumber: number = 0;

  dialogOpened: boolean = false;

  constructor(private neithServiceProvider: NeithServiceProviderService,
              private sanitizer: DomSanitizer,
              private dialog: MatDialog,
              private tokenStorageService: TokenStorageService,
              private translate: TranslateService,
              private router: Router) {
    this.neithServiceProvider.getUser().subscribe(users => {
      this.user = users[0];
      this.userId = this.user.id;
      this.hasNeithDemo = this.user.has_neith_demo;
      this.hasNeithAIActive = this.user.has_neith_ai_active;
      this.chatLicenseType = this.user.license_type ? this.user.license_type.id : 11;
      this.hasHeru = this.user.has_neith_gpt || this.user.has_neith_gpt_demo;
      this.neithDemoMessagesNumber = this.user.neith_demo_messages_number;
      if (!this.hasNeithAIActive && !this.hasNeithDemo) {
        if (this.user.has_neith_gpt || this.user.has_neith_gpt_demo) {
          this.openNeithGPT();
          return;
        } else {
          this.router.navigate(['/login']);
          return;
        }
      }
      if (this.hasNeithDemo && !this.hasNeithAIActive) {
        this.openDemoModal();
      }
      this.getAIConfig();
    }, error => {
      this.router.navigate(['/login']);
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    if (this.quizListOpened || this.dialogOpened) {
      return;
    }
    this.scrollToBottom();
  }

  getAIConfig() {
    this.showLoading();
    this.neithServiceProvider.getAIConfig(this.user.driving_school_neith).subscribe((data) => {
        this.aiConfig = data[0];
        this.createChat();
        this.getCompletedManuals();
        // this.askForLanguage();
        // this.sendHello();
      },
      err => {
        // this.loading.dismiss();
      });
  }

  askForLanguage() {
    this.messages.push({
      fromAi: true,
      isLanguageSelector: true,
      text: 'Seleziona la lingua della chat'
    });
  }

  sendHello(language) {
    this.showLoading();
    this.showIntroVideo();
    let welcome_messages = [];
    if (this.aiConfig) {
      this.aiConfig.welcome_message_one == '' ? '' : welcome_messages.push(this.aiConfig.welcome_message_one);
      this.aiConfig.welcome_message_two == '' ? '' : welcome_messages.push(this.aiConfig.welcome_message_two);
      this.aiConfig.welcome_message_three == '' ? '' : welcome_messages.push(this.aiConfig.welcome_message_three);
    }
    if (this.aiConfig && welcome_messages.length > 0) {
      let index = Math.floor((Math.random() * welcome_messages.length));
      this.neithServiceProvider.translate(welcome_messages[index], language).subscribe(result => {
        this.messages.push({fromAi: true, text: result['text']});
        this.messages.push({
          fromAi: true,
          text: 'Vuoi che ti faccia lezione o vuoi farmi domande specifiche su un capitolo? Per selezionare il capitolo cliccal sul + che trovi in basso a sinistra'
        });
        this.getLastStats();
      });
    } else {
      if (this.aiConfig && this.aiConfig.name) {
        this.aiName = this.aiConfig.name;
      }
      this.neithServiceProvider.sendAiHello(this.user.firstname, this.aiName).subscribe((response) => {
        this.neithServiceProvider.translate(response['text'], language).subscribe(result => {
          this.messages.push({fromAi: true, text: result['text']});
          this.messages.push({
            fromAi: true,
            text: 'Vuoi che ti faccia lezione o vuoi farmi domande specifiche su un capitolo? Per selezionare il capitolo cliccal sul + che trovi in basso a sinistra'
          });
          this.getLastStats();
        }, error => {
          this.messages.push({fromAi: true, text: 'Scusami, stavo giocando a scacchi con Siri. Puoi ripetere la domanda?'});
        });
        // this.loading.dismiss()
      }, error => {
        this.messages.push({fromAi: true, text: 'Scusami, stavo giocando a scacchi con Siri. Puoi ripetere la domanda?'});
        // this.loading.dismiss()
      });
    }
  }

  getLastStats() {
    if (this.chatLicenseType !== this.LICENSE_TYPE_B) {
      return;
    }
    this.neithServiceProvider.getLastStats(this.chatId).subscribe((stats) => {
      this.lastStats = stats;
      if (this.lastStats) {
        let manualsTitles = [];
        let promises = [];
        let translatedManuals = [];
        this.lastStats.forEach(stats => {
          manualsTitles.push(stats.manual_title);
        });
        this.translate.get('Nell\'ultima lezione abbiamo affrontato questi argomenti:').subscribe(header => {
          this.translate.get('Queste sono le statistiche in base ai quiz svolti, ti consiglio di ripassare gli argomenti con una percentuale di errori  maggiore o uguale al 30%').subscribe(footer => {
            manualsTitles.forEach(title => {
              promises.push(new Promise((resolve, reject) => {
                this.translate.get(title).subscribe(result => {
                  translatedManuals.push(result);
                  resolve();
                }, error => {
                  reject();
                });
              }));
            });
            Promise.all(promises).then((result) => {
              this.messages[this.statsMessageIndex] = {
                fromAi: true,
                text: `<b>${header}</b>
                   <br><br>
                   ${translatedManuals.join('<br>')}
                   <br><br>
                   ${footer}`
              };
            });
            this.statsMessageIndex = this.messages.length - 1;
            this.translate.get('Nessun quiz svolto').subscribe(noQuiz => {
              this.translate.get('di errori').subscribe(ofErrors => {
                this.lastStats.forEach(stats => {
                  let text = '';
                  if (stats.not_answered_count == +stats.total) {
                    this.translate.get('' + stats.manual_title).subscribe(result => {
                      text = `<b>${result} -> ${noQuiz}</b>`;
                    });
                  } else {
                    this.translate.get('' + stats.manual_title).subscribe(result => {
                      text = `<b>${result} -> ${stats.errors_percentage}% ${ofErrors}</b>`;
                    });
                  }
                  this.messages.push({
                    fromAi: true,
                    text: text,
                    selectedArgument: +stats.argument_position,
                    manualIndex: +stats.manual_index,
                    isReview: true
                  });
                  this.lessonReviewMessagesIndexes.push(this.messages.length - 1);
                });
              });
            });
          });
        });
      }
    }, e => {

    });
  }

  startLessonDelayedText() {
    if (this.currentLessonIndex < this.currentLessonText.length) {
      setTimeout(() => {
        this.messages[this.currentLessonMessageIndex].text += this.currentLessonText[this.currentLessonIndex] ? this.currentLessonText[this.currentLessonIndex] : '';
        this.currentLessonIndex++;
        this.startLessonDelayedText();
      }, 50);
    } else {
      this.isTeaching = false;
      this.messages[this.currentLessonMessageIndex].isAudioPlaying = false;
      if (this.currentVideoMessageIndex > -1) {
        this.messages[this.currentVideoMessageIndex].hideMessage = false;
      }
    }
  }

  startTeaching(message) {
    this.isTeaching = true;
    message.isTeaching = true;
    let manual = this.manuals[this.manualIndex];
    if (this.chatLicenseType == this.LICENSE_TYPE_B) {
      this.teachArgument();
    } else {
      this.teachSuperiorArgument();
    }
    this.thereAreOtherImages(manual.id);
    // this.thereIsAVideo(manual.id);
  }

  stopLesson() {
    this.isTeaching = false;
    this.currentLessonIndex = this.currentLessonText.length;
    this.messages[this.currentLessonMessageIndex].text = this.currentLessonText;
    if (this.currentVideoMessageIndex > -1) {
      this.messages[this.currentVideoMessageIndex].hideMessage = false;
    }
    this.stopAudio(this.messages[this.currentLessonMessageIndex]);
    if (this.sub) {
      this.sub.unsubscribe();
    }
    let manual = this.manuals[this.manualIndex];
    if (!manual) {
      return;
    }
    this.thereAreOtherImages(manual.id);
    // this.thereIsAVideo(manual.id);
  }

  nextParagraph(message) {
    this.isTeaching = true;
    message.isTeaching = false;
    if (this.chatLicenseType == this.LICENSE_TYPE_B) {
      this.teachArgument();
    } else {
      this.teachSuperiorArgument();
    }
    let manual = this.manuals[this.manualIndex];
    if (!manual) {
      return;
    }
    this.thereAreOtherImages(manual.id);
    // this.thereIsAVideo(manual.id);
  }

  showQuizzes(messageIndex, message) {
    message.quizShown = true;
    this.getManualQuizzes(messageIndex, message.manualId, false, null, message.isOldMessage);
    this.quizListOpened = true;
  }

  showQuizAnswers(messageIndex, trueQuizzes, message) {
    if (trueQuizzes) {
      message.trueQuizShown = true;
    } else {
      message.falseQuizShown = true;
    }
    this.getManualQuizzes(messageIndex, message.manualId, true, trueQuizzes, message.isOldMessage);
    this.quizListOpened = true;
  }

  setUserAnswer(message, userAnswer, isOldMessage) {
    message.userAnswer = userAnswer;
    message.userHasAnswered = true;
    if (isOldMessage) {
      return;
    }
    if (message.answer === message.userAnswer) {
      this.correctQuizzes.push([message.quizId, message.manualId]);
    } else {
      this.incorrectQuizzes.push([message.quizId, message.manualId]);
    }
    if (this.chatLicenseType == this.LICENSE_TYPE_B) {
      this.updateStats();
    }
  }

  teachArgument() {
    this.quizListOpened = false;
    this.dialogOpened = false;
    this.isLoading = true;
    this.teachByChatGPT();
  }

  teachSuperiorArgument() {
    this.quizListOpened = false;
    this.dialogOpened = false;
    this.isLoading = true;
    this.teachSuperiorByChatGPT();
  }

  getManualQuizzes(messageIndex, manualId, onlyAnswers, trueQuizzes?, isOldMessage?) {
    this.isLoading = true;
    this.neithServiceProvider.getQuizzesFromManual(manualId, this.selectedLang).subscribe(quizzes => {
      if (onlyAnswers) {
        quizzes.forEach(quiz => {
          if (quiz.result == trueQuizzes) {
            this.messages.splice(messageIndex + 1, 0, {
              text: quiz.text,
              fromAi: true,
              isQuizText: true,
              answer: quiz.result,
              isOldMessage: isOldMessage
            });
          }
        });
        this.messages.splice(messageIndex + 1, 0, {
          text: trueQuizzes ? `<b>Quiz con risposta VERO</b>` : `<b>Quiz con risposta FALSO</b>`,
          fromAi: true
        });
      } else {
        quizzes.forEach(quiz => {
          this.messages.splice(messageIndex + 1, 0, {
            text: quiz.text,
            answer: quiz.result,
            quizId: quiz.id,
            manualId: manualId,
            fromAi: true,
            isQuiz: true,
            isOldMessage: isOldMessage
          });
        });
      }
      this.isLoading = false;
    });
  }

  getQuizzesLength(manualId, manualTitle) {
    this.neithServiceProvider.getQuizzesFromManual(manualId, this.selectedLang).subscribe(quizzes => {
      this.studiedManuals.push([manualId, manualTitle, quizzes.length, this.selectedArgument, this.manualIndex - 1]);
      let message = this.messages[this.currentLessonMessageIndex];
      message.hasQuizzes = quizzes.length > 0;
      this.updateStats();
    });
  }

  teachByChatGPT() {
    let manual = this.manuals[this.manualIndex];
    this.sub = this.neithServiceProvider.startTeaching(this.selectedArgument, this.manualIndex + 1, this.chatId, manual.id, manual.title, manual.image_url, this.isTeaching).subscribe((response) => {
      this.manageTeachingResponse(response);
    }, error => {
      this.messages.push({
        fromAi: true,
        text: 'Scusami, stavo giocando a scacchi con Siri. Puoi ripetere la domanda?',
        isError: true
      });
      this.isLoading = false;
    });
  }

  teachSuperiorByChatGPT() {
    let manual = this.manuals[this.manualIndex];
    this.sub = this.neithServiceProvider.startTeachingSuperior(this.selectedArgument, this.manualIndex, this.chatId, this.isTeaching).subscribe((response) => {
      this.manageTeachingSuperiorManualResponse(response, manual);
    }, error => {
      this.messages.push({
        fromAi: true,
        text: 'Scusami, stavo giocando a scacchi con Siri. Puoi ripetere la domanda?',
        isError: true
      });
      this.isLoading = false;
    });
  }

  selectArgument(selected, argument) {
    this.selectedArgument = selected;
    this.manuals = [];
    this.messages.forEach(message => message.isArgumentName = false);
    if (!this.isReview) {
      if (this.isTeaching) {
        this.isLoading = false;
        this.stopLesson();
      }
      this.messages.push({fromAi: false, text: argument.name, isArgumentName: true});
    }
    if (this.chatLicenseType == this.LICENSE_TYPE_B) {
      this.getBManuals(argument);
    } else {
      this.getSuperiorManuals(argument);
    }
  }


  getBManuals(argument) {
    this.neithServiceProvider.getManuals(argument.id).subscribe(response => {
      response.forEach(element => {
        this.manuals.push({
          id: element.id,
          title: element.title_it,
          image_url: element.image ? element.image.image : null
        });
      });
      if (this.isReview) {
        this.teachArgument();
        this.thereAreOtherImages(this.manuals[this.manualIndex].id);
        // this.thereIsAVideo(this.manuals[this.manualIndex].id);
      }
    }, error => {

    });
  }

  getSuperiorManuals(argument) {
    this.neithServiceProvider.getSuperiorManuals(argument.id).subscribe(response => {
      response.forEach(element => {
        this.manuals.push({
          title: element
        });
      });
    });
  }

  sendPrompt() {
    if (!this.promptString && this.selectedImageIndex < 0) {
      return;
    }
    if (this.hasNeithDemo && !this.hasNeithAIActive) {
      let body = {
        'neith_demo_messages_number': this.neithDemoMessagesNumber + 1
      };
      this.neithServiceProvider.updateUser(this.user.id, body).subscribe((result) => {
        this.neithDemoMessagesNumber += 1;
      }, error => {
      });
    }
    if (this.currentLessonText && !this.isResume && !this.quizListOpened) {
      this.currentLessonIndex = this.currentLessonText.length;
      this.messages[this.messages.length - 1].text = this.currentLessonText;
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.quizListOpened = false;
    this.dialogOpened = false;
    this.isLoading = true;
    this.messages.push({
      fromAi: false,
      text: this.promptString
    });
    this.lastPrompt = '' + this.promptString;
    this.sendChatGPTPrompt(this.promptString);
    this.promptString = '';
  }

  explainImage() {
    this.quizListOpened = false;
    this.dialogOpened = false;
    this.isLoading = true;
    this.lastPrompt = '' + this.promptString;
    this.promptString = '';
    let image;
    this.images.forEach(im => {
      if (im.id == this.selectedImageId) {
        image = im;
      }
    });
    this.messages.push({
      fromAi: false,
      text: image.name
    });
    this.neithServiceProvider.explainImage(image.id, this.chatId).subscribe((response) => {
      this.manageResponse(response);
    }, error => {
      this.manageError();
    });
  }

  sendFallbackPrompt(prompt) {
    this.neithServiceProvider.sendAiFallbackPrompt(prompt, this.lastResponse).subscribe((response) => {
      this.manageResponse(response);
    }, error => {
      this.manageError();
    });
  }

  sendChatGPTPrompt(prompt) {
    // this.neithServiceProvider.sendAiPrompt(prompt, '').subscribe((response) => {
    this.neithServiceProvider.sendAiPrompt(prompt, this.chatId, this.chatLicenseType).subscribe((response) => {
      this.manageResponse(response);
    }, error => {
      this.manageError();
    });
  }

  manageResponse(response) {
    this.isResume = false;
    this.isReview = false;
    let text = response['text'];
    this.lastResponse = text;
    this.messages.push({
      fromAi: true,
      text: ``,
      imageIndex: this.selectedImageIndex >= 0 ? this.selectedImageIndex : null,
      audioUrl: response['audio_url']
    });
    this.selectedImageIndex = -1;
    this.isLoading = false;
    this.currentLessonText = text;
    this.currentLessonIndex = 0;
    this.currentLessonMessageIndex = this.messages.length - 1;
    this.startLessonDelayedText();
    // this.listenToLesson(response['audio_url'], this.messages[this.currentLessonMessageIndex]);
  }

  manageError() {
    this.messages.push({
      fromAi: true,
      text: 'Scusami, stavo giocando a scacchi con Siri. Puoi ripetere la domanda?',
      isError: true
    });
    this.selectedImageIndex = -1;
    this.lastPrompt = '' + this.promptString;
    this.promptString = '';
    this.isLoading = false;
  }


  manageTeachingResponse(response) {
    this.isReview = false;
    let manual = this.manuals[this.manualIndex];
    this.messages.push({
      fromAi: true,
      imageUrl: manual.image_url,
      text: ``,
      isTeaching: this.isTeaching,
      manualName: manual.title,
      manualId: manual.id,
      audioUrl: response['audio_url']
    });
    this.translate.get('' + manual.title).subscribe((title) => {
      this.currentLessonText = `<b>${title}</b><br>` + response['text'];
      this.lastResponse = response['text'];
      this.currentLessonIndex = 0;
      this.currentLessonMessageIndex = this.messages.length - 1;
      this.startLessonDelayedText();
      this.setCompletedManual(manual.id);
      // this.listenToLesson(response['audio_url'], this.messages[this.currentLessonMessageIndex]);
      this.manualIndex++;
      this.getQuizzesLength(manual.id, manual.title);
      this.isLoading = false;
      this.currentLessonTitle = title;
      this.thereIsAVideo(manual.id);
    });
  }

  showLoading() {
    // this.loading = this.loadingCtrl.create({
    //   content: 'Lasciami pensare...',
    // });
    // this.loading.present();
  }


  getArguments() {
    this.neithServiceProvider.getArgumentsFromLicenseType(this.chatLicenseType, this.user.driving_school_neith).subscribe((data) => {
        this.arguments = data;
        if (this.chatLicenseType == this.LICENSE_TYPE_B) {
          this.loadImages();
        }
      },
      err => {
        // this.loading.dismiss();
      });
  }

  getCompletedManuals() {
    this.neithServiceProvider.getCompletedManuals(this.userId).subscribe(response => {
      this.completedManuals = response['completed_manuals'];
    });
  }

  setCompletedManual(manualId) {
    this.neithServiceProvider.setCompletedManual(this.userId, manualId).subscribe(response => {
      if (this.completedManuals.indexOf(manualId) < 0) {
        this.completedManuals.push(manualId);
      }
    });
  }

  createChat() {
    this.neithServiceProvider.createChat(this.user.id).subscribe((response) => {
      this.introVideoShown = response['intro_video_shown'];
      this.chatId = response['chat'];
      this.translate.use(this.langCodes[+response['language']]);
      this.getArguments();
      this.neithServiceProvider.getChatMessages(this.userId, this.chatId).subscribe((messages) => {
        messages.forEach(message => {
          this.messages.push({
            fromAi: message.role == 2,
            text: message.translated_message ? message.translated_message : message.message,
            audioUrl: message.audioUrl != '' ? message.audio_url : null,
            date: new Date(message.created_datetime).toLocaleString('it-IT', this.options),
            manualId: message.manual_id,
            manualName: message.manual_title,
            imageUrl: message.image_url,
            isTeaching: message.isTeaching,
            isOldMessage: true
          });
        });
        this.askForLanguage();
        this.sendHello(+response['language']);
      });
    }, error => {
    });
  }

  loadImages() {
    let promises = [];
    this.images = [];
    this.arguments.forEach(argument => {
      promises.push(new Promise((resolve, reject) => {
        this.neithServiceProvider.getManuals(argument.id).subscribe(response => {
          response.forEach(element => {
            if (element.image) {
              this.images.push({id: element.image.id, name: element.title_it, url: element.image.image});
            }
          });
          this.images.sort((a, b) => (a.id < b.id ? -1 : 1));
          resolve();
        }, error => {
          reject(error);
        });
      }));
    });
    Promise.all(promises).then(() => {
      // this.loading.dismiss();
    }).catch((error) => {
      // this.loading.dismiss();
    });
  }

  openImagesModal(manualId?: number, isVideo?: boolean, isOldMessage?: boolean) {
    this.dialogOpened = true;
    if (isOldMessage && !isVideo) {
      this.openOldImages(manualId);
      return;
    } else if (isOldMessage && isVideo) {
      this.openOldVideo(manualId);
      return;
    }
    if (manualId && !isVideo) {
      this.otherImages = this.otherImages.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.url === value.url && t.manualId === value.manualId
          ))
      );
    }
    const dialog = this.dialog.open(ImagesDialogComponent, {
      data: {
        images: manualId && !isVideo ? this.otherImages.filter(image => image.manualId == manualId) : this.images,
        manualId: manualId || null,
        videoUrl: isVideo ? this.videos.filter(video => video.manualId == manualId)[0].url : null,
        isVideo: isVideo,
        hasNeithDemo: this.hasNeithDemo,
        hasNeithAIActive: this.hasNeithAIActive
      },
      panelClass: 'fullscreen-dialog'
    });
    dialog.afterClosed().subscribe((data) => {
      if (data && data.imageIndex >= 0) {
        this.selectedImageIndex = data.imageIndex;
        this.selectedImageId = data.imageId;
        this.lastResponse = '';
        this.explainImage();
      }
    });
  }

  openOldImages(manualId: number) {
    this.neithServiceProvider.getManualOtherImages(manualId).subscribe(response => {
      response['images'].forEach(url => {
        if (this.otherImages.indexOf({manualId: manualId, url: url}) === -1) {
          this.otherImages.push({manualId: manualId, url: url});
        }
      });
      this.otherImages = this.otherImages.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.url === value.url && t.manualId === value.manualId
          ))
      );
      this.dialog.open(ImagesDialogComponent, {
        data: {
          images: manualId ? this.otherImages.filter(image => image.manualId == manualId) : this.images,
          manualId: manualId || null,
          videoUrl: null,
          isVideo: false,
          hasNeithDemo: this.hasNeithDemo,
          hasNeithAIActive: this.hasNeithAIActive
        },
        panelClass: 'fullscreen-dialog'
      });
    });
  }

  openOldVideo(manualId: number) {
    this.neithServiceProvider.getManualVideo(manualId).subscribe(response => {
      let url_safe_resource = this.sanitizer.bypassSecurityTrustResourceUrl(response['video_url']);
      this.videos.push({manualId: manualId, url: url_safe_resource});
      this.dialog.open(ImagesDialogComponent, {
        data: {
          images: this.images,
          manualId: manualId || null,
          videoUrl: this.videos.filter(video => video.manualId == manualId).length > 0 ? this.videos.filter(video => video.manualId == manualId)[0].url : null,
          isVideo: true,
          hasNeithDemo: this.hasNeithDemo,
          hasNeithAIActive: this.hasNeithAIActive
        },
        panelClass: 'fullscreen-dialog'
      });
    });
  }

  openArgumentsModal() {
    const dialog = this.dialog.open(ArgumentsDialogComponent, {
      data: {
        arguments: this.arguments,
        hasNeithDemo: this.hasNeithDemo,
        hasNeithAIActive: this.hasNeithAIActive,
        completedManuals: this.completedManuals,
        chatLicenseType: this.chatLicenseType
      },
      panelClass: 'fullscreen-dialog'
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.manuals = data.manuals;
      }
      if (this.chatLicenseType !== this.LICENSE_TYPE_B) {
        if (data.quizSelected && data.selectedArgument) {
          this.selectedArgument = data.selectedArgument;
          this.getSuperiorQuizzes();
        }
      }
      if (data && data.selectedArgument >= 0 && data.argument) {
        this.selectArgument(data.selectedArgument, data.argument);
        this.quizListOpened = false;
        this.dialogOpened = false;
        this.manualIndex = 0;
      } else if (data && data.manualIndex >= 0 && data.selectedArgument) {
        this.manualIndex = data.manualIndex;
        this.selectedArgument = data.selectedArgument;
        this.messages.push({
          fromAi: false,
          text: data.manual.title
        });
        if (this.chatLicenseType == this.LICENSE_TYPE_B) {
          this.teachManual(data.manual);
        } else {
          this.teachSuperiorManual(data.manual);
        }
      }
    });
  }

  teachManual(manual) {
    this.quizListOpened = false;
    this.dialogOpened = false;
    this.isLoading = true;
    this.isTeaching = true;
    this.teachManualByChatGPT(manual);
    this.thereAreOtherImages(manual.id);
  }

  teachSuperiorManual(manual) {
    this.quizListOpened = false;
    this.dialogOpened = false;
    this.isLoading = true;
    this.isTeaching = true;
    this.teachSuperiorManualByChatGPT(manual);
  }

  teachSuperiorManualByChatGPT(manual) {
    this.sub = this.neithServiceProvider.startTeachingSuperior(this.selectedArgument, this.manualIndex, this.chatId, this.isTeaching).subscribe((response) => {
      this.manageTeachingSuperiorManualResponse(response, manual);
    }, error => {
      this.messages.push({
        fromAi: true,
        text: 'Scusami, stavo giocando a scacchi con Siri. Puoi ripetere la domanda?',
        isError: true
      });
      this.isLoading = false;
    });
  }

  teachManualByChatGPT(manual) {
    this.sub = this.neithServiceProvider.startTeaching(this.selectedArgument, this.manualIndex + 1, this.chatId, manual.id, manual.title, manual.image_url, this.isTeaching).subscribe((response) => {
      this.manageTeachingManualResponse(response, manual);
    }, error => {
      this.messages.push({
        fromAi: true,
        text: 'Scusami, stavo giocando a scacchi con Siri. Puoi ripetere la domanda?',
        isError: true
      });
      this.isLoading = false;
    });
  }

  manageTeachingSuperiorManualResponse(response, manual) {
    this.isReview = false;
    this.messages.push({
      fromAi: true,
      text: ``,
      isTeaching: this.isTeaching,
      manualName: manual.title,
      audioUrl: response['audio_url']
    });
    this.translate.get('' + manual.title).subscribe((title) => {
      this.currentLessonText = `<b>${title}</b><br>` + response['text'];
      this.lastResponse = response['text'];
      this.currentLessonIndex = 0;
      this.currentLessonMessageIndex = this.messages.length - 1;
      this.startLessonDelayedText();
      // this.listenToLesson(response['audio_url'], this.messages[this.currentLessonMessageIndex]);
      this.manualIndex++;
      // this.getQuizzesLength(manual.id, manual.title);
      this.isLoading = false;
      this.currentLessonTitle = title;
    });
  }

  manageTeachingManualResponse(response, manual) {
    this.isReview = false;
    this.messages.push({
      fromAi: true,
      imageUrl: manual.image_url,
      text: ``,
      isTeaching: this.isTeaching,
      manualName: manual.title,
      manualId: manual.id,
      audioUrl: response['audio_url']
    });
    this.translate.get('' + manual.title).subscribe((title) => {
      this.currentLessonText = `<b>${title}</b><br>` + response['text'];
      this.lastResponse = response['text'];
      this.currentLessonIndex = 0;
      this.currentLessonMessageIndex = this.messages.length - 1;
      this.setCompletedManual(manual.id);
      this.startLessonDelayedText();
      // this.listenToLesson(response['audio_url'], this.messages[this.currentLessonMessageIndex]);
      this.manualIndex++;
      this.getQuizzesLength(manual.id, manual.title);
      this.isLoading = false;
      this.currentLessonTitle = title;
      this.thereIsAVideo(manual.id);
    });
  }

  scrollToBottom() {
    try {
      this.scrollScene.nativeElement.scrollTop = this.scrollScene.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

  thereAreOtherImages(manualId) {
    this.neithServiceProvider.getManualOtherImages(manualId).subscribe(response => {
      response['images'].forEach(url => {
        if (this.otherImages.indexOf({manualId: manualId, url: url}) === -1) {
          this.otherImages.push({manualId: manualId, url: url});
        }
      });
    });
  }

  filteredOtherImagesLength(manualId) {
    return this.otherImages.filter(image => image.manualId == manualId).length;
  }

  thereIsAnAssociatedVideo(manualId) {
    return this.videos.filter(video => video.manualId == manualId).length > 0;
  }

  thereIsAVideo(manualId) {
    this.neithServiceProvider.getManualVideo(manualId).subscribe(response => {
      let url_safe_resource = this.sanitizer.bypassSecurityTrustResourceUrl(response['video_url']);
      this.videos.push({manualId: manualId, url: url_safe_resource});
      let index = Math.floor((Math.random() * this.videoSentences.length));
      this.messages.push({
        fromAi: true,
        text: this.videoSentences[index],
        manualId: manualId,
        isVideo: true,
        hideMessage: true
      });
      this.currentVideoMessageIndex = this.messages.length - 1;
    });
  }

  resume(message) {
    if (this.isTeaching) {
      this.teachArgument();
    } else {
      let userMessages = this.messages.filter(message => message.fromAi == false);
      this.promptString = userMessages[userMessages.length - 1].text;
      this.isResume = true;
      this.sendPrompt();
    }
    message.isError = false;
  }

  updateStats() {
    if (this.currentStats) {
      this.neithServiceProvider.updateStats(this.currentStats.id, this.studiedManuals, this.correctQuizzes, this.incorrectQuizzes).subscribe(response => {
        this.currentStats = response;
      }, error => {

      });
    } else {
      this.neithServiceProvider.createStats(this.chatId, this.studiedManuals, this.correctQuizzes, this.incorrectQuizzes).subscribe(response => {
        this.currentStats = response;
      }, error => {

      });
    }
  }

  reviewLesson(message) {
    this.isReview = true;
    this.isTeaching = true;
    this.manualIndex = message.manualIndex;
    message.isReview = false;
    let argument = null;
    this.arguments.forEach(a => {
      if (a.position == message.selectedArgument) {
        argument = a;
      }
    });
    this.selectArgument(message.selectedArgument, argument);
  }

  listenToLesson(message) {
    // this.audioLesson = new AudioContext()
    // const source = this.audioLesson.createBufferSource()
    // this.neithServiceProvider.getAudioStream(audioUrl).subscribe(response => {
    //   this.audioLesson.decodeAudioData(response, (buffer) => {
    //     source.buffer = buffer;
    //     source.connect(this.audioLesson.destination);
    //     source.loop = false;
    //   })
    //   source.start(0);
    //   message.isAudioPlaying = true;
    // })
    // // this.audioLesson.src = audioUrl;
    // // this.audioLesson.load();
    // // this.audioLesson.play();
    // // message.isAudioPlaying = true;
    this.audioLesson = new Audio();
    this.audioLesson.src = message.audioUrl;
    this.audioLesson.load();
    this.audioLesson.play();
    message.isAudioPlaying = true;
  }

  stopAudio(message) {
    if (!this.audioLesson) {
      return;
    }
    this.audioLesson.pause();
    this.audioLesson.currentTime = 0;
    message.isAudioPlaying = false;
  }

  setLanguage(language) {
    const languages = [
      `<img src='../../../assets/img/flags/italy.svg' alt="" width="15">Italiano`,
      `<img src='../../../assets/img/flags/united-kingdom.svg' alt="" width="15">Inglese`,
      `<img src='../../../assets/img/flags/spain.svg' alt="" width="15">Spagnolo`,
      `<img src='../../../assets/img/flags/france.svg' alt="" width="15">Francese`,
      `<img src='../../../assets/img/flags/germany.svg' alt="" width="15">Tedesco`,
      `<img src='../../../assets/img/flags/china.svg' alt="" width="15">Cinese`,
      `<img src='../../../assets/img/flags/russia.svg' alt="" width="15">Russo`,
      `<img src='../../../assets/img/flags/romania.svg' alt="" width="15">Rumeno`,
      `<img src='../../../assets/img/flags/ukraine.svg' alt="" width="15">Ucraino`
    ];
    const responses = [
      `Lingua impostata su: Italiano`,
      `Language set to: English`,
      `Idioma configurado para: Español`,
      `Langue réglée sur: Français`,
      `Sprache eingestellt auf: Deutsch`,
      '语言设置为：中文',
      'Язык установлен на: Русский',
      'Limba setată la: română',
      'Мова встановлена: українська'
    ];
    this.messages.push({
      fromAi: false,
      text: languages[language]
    });
    this.neithServiceProvider.updateChatLanguage(this.chatId, language).subscribe(response => {
      this.messages.push({
        fromAi: true,
        text: responses[language]
      });
      this.translate.use(this.langCodes[language]);
      this.selectedLang = this.langCodes[language];
      this.translateHelloMessage(language);
      this.translateStats();
    }, error => {
    });
  }

  translateHelloMessage(language) {
    this.neithServiceProvider.translate(this.messages[1].text, language).subscribe(result => {
      this.messages[1] = {fromAi: true, text: result['text']};
    }, error => {

    });
  }

  translateStats() {
    if (this.lastStats) {
      let promises = [];
      let translatedManuals = [];
      let manualsTitles = [];
      this.lastStats.forEach(stats => {
        manualsTitles.push(stats.manual_title);
      });
      this.translate.get('Nell\'ultima lezione abbiamo affrontato questi argomenti:').subscribe(header => {
        this.translate.get('Queste sono le statistiche in base ai quiz svolti, ti consiglio di ripassare gli argomenti con una percentuale di errori  maggiore o uguale al 30%').subscribe(footer => {
          manualsTitles.forEach(title => {
            promises.push(new Promise((resolve, reject) => {
              this.translate.get(title).subscribe(result => {
                translatedManuals.push(result);
                resolve();
              }, error => {
                reject();
              });
            }));
          });
          Promise.all(promises).then((result) => {
            this.messages[this.statsMessageIndex] = {
              fromAi: true,
              text: `<b>${header}</b>
                   <br><br>
                   ${translatedManuals.join('<br>')}
                   <br><br>
                   ${footer}`
            };
          });
        });
      });
      let translatedMessages = [];
      this.translate.get('Nessun quiz svolto').subscribe(noQuiz => {
        this.translate.get('di errori').subscribe(ofErrors => {
          this.lastStats.forEach(stats => {
            let text = '';
            if (stats.not_answered_count == +stats.total) {
              this.translate.get('' + stats.manual_title).subscribe(result => {
                text = `<b>${result} -> ${noQuiz}</b>`;
              });
            } else {
              this.translate.get('' + stats.manual_title).subscribe(result => {
                text = `<b>${result} -> ${stats.errors_percentage}% ${ofErrors}</b>`;
              });
            }
            translatedMessages.push({
              fromAi: true,
              text: text,
              selectedArgument: +stats.argument_position,
              manualIndex: +stats.manual_index,
              isReview: true
            });
          });
          this.lessonReviewMessagesIndexes.forEach((value, index) => {
            this.messages[value] = translatedMessages[index];
          });
        });
      });
    }
  }

  scrollToLesson() {
    const el = this.components.toArray()[this.currentLessonMessageIndex];
    el.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  goToShop() {
    window.location.href = 'https://guidaevai.com/prodotto/pacchetto-neith-insegnante-virtuale/';
  }

  openDemoModal() {
    const dialog = this.dialog.open(DemoDialogComponent);
  }

  showIntroVideo() {
    if (!this.introVideoShown) {
      const dialog = this.dialog.open(DemoIntroDialogComponent, {
        panelClass: 'fullscreen-dialog'
      });
      dialog.afterClosed().subscribe(() => {
        this.neithServiceProvider.setIntroVideoShown(this.chatId).subscribe(() => {
        }, error => {
        });
      });
    }
  }

  getSuperiorQuizzes() {
    this.neithServiceProvider.getSuperiorQuizzes(this.chatLicenseType, this.selectedArgument, this.selectedLang).subscribe(result => {
      const shuffled = result.sort(() => 0.5 - Math.random());
      shuffled.splice(0, 10).forEach(element => {
        this.messages.push({
            text: element.text,
            answer: element.result,
            quizId: element.id,
            fromAi: true,
            isQuiz: true
          }
        );
      });
    });
  }

  openNeithGPT() {
    if (this.hasHeru) {
      this.router.navigate(['/heru']);
    } else {
      this.showHeruDialog();
    }
  }

  showHeruDialog() {
    const dialog = this.dialog.open(HeruDemoDialogComponent);
  }

  openVideosModal() {
    this.dialog.open(SuperiorVideosDialogComponent, {panelClass: 'fullscreen-dialog'});
  }

}
