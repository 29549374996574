import {Component, Inject, OnInit} from '@angular/core';
import {Argument} from '../../core/models';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NeithServiceProviderService} from '../../core/providers/neith-service-provider';
import {BuyDialogComponent} from '../buy-dialog/buy-dialog.component';

@Component({
  selector: 'app-arguments-dialog',
  templateUrl: './arguments-dialog.component.html',
  styleUrls: ['./arguments-dialog.component.scss']
})
export class ArgumentsDialogComponent implements OnInit {

  arguments: Argument[] = [];
  selectedArgument: Argument = null;
  chatLicenseType: number = 11;
  manuals: {
    id?: number;
    title: String;
    image_url?: String;
  }[] = [];
  completedManuals: number[] = [];

  hasNeithDemo: boolean = true;
  hasNeithAIActive: boolean = false;
  quizSelected: boolean = true;

  constructor(
    private neithServiceProvider: NeithServiceProviderService,
    private dialogRef: MatDialogRef<ArgumentsDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.arguments = this.data.arguments;
    this.hasNeithDemo = this.data.hasNeithDemo;
    this.hasNeithAIActive = this.data.hasNeithAIActive;
    this.completedManuals = this.data.completedManuals;
    this.chatLicenseType = this.data.chatLicenseType;
  }

  ngOnInit(): void {
  }

  selectArgument(argument) {
    this.selectedArgument = argument;
    if (this.chatLicenseType == 11) {
      this.getManuals();
    } else {
      this.getSuperiorManuals();
    }
  }

  selectManual(manual, index) {
    this.dialogRef.close({
      manualIndex: index,
      manual: manual,
      selectedArgument: this.chatLicenseType == 11 ? this.selectedArgument.position : this.selectedArgument.id,
      manuals: this.manuals
    });
  }

  teachArgument() {
    this.dialogRef.close({
      selectedArgument: this.chatLicenseType == 11 ? this.selectedArgument.position : this.selectedArgument.id,
      argument: this.selectedArgument,
      manuals: this.manuals
    });
  }

  getManuals() {
    this.neithServiceProvider.getManuals(this.selectedArgument.id).subscribe(response => {
      this.manuals = [];
      response.forEach(element => {
        this.manuals.push({
          id: element.id,
          title: element.title_it,
          image_url: element.image ? element.image.image : null
        });
      });
    }, error => {

    });
  }

  getSuperiorManuals() {
    this.neithServiceProvider.getSuperiorManuals(this.selectedArgument.id).subscribe(response => {
      this.manuals = [];
      response.forEach(element => {
        this.manuals.push({
          title: element
        });
      });
    });
  }

  dismiss() {
    this.dialogRef.close({
      manuals: this.manuals
    });
  }

  selectQuiz(argument) {
    this.quizSelected = true;
    this.selectedArgument = argument;
    this.dialogRef.close({
      manuals: this.manuals,
      quizSelected: this.quizSelected,
      selectedArgument: this.selectedArgument.id
    });
  }

  showDemoDialog() {
    const dialog = this.dialog.open(BuyDialogComponent);
  }
}
