import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NeithServiceProviderService} from '../../core/providers/neith-service-provider';
import {Router} from '@angular/router';

@Component({
  selector: 'app-teacher-chat',
  templateUrl: './teacher-chat.component.html',
  styleUrls: ['./teacher-chat.component.scss']
})
export class TeacherChatComponent implements OnInit, AfterViewChecked {

  @ViewChild('scrollScene') private scrollScene: ElementRef;

  options = {'weekday': 'short', 'month': 'short', 'day': '2-digit'};
  today: String = new Date().toLocaleString('it-IT', this.options);
  yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date).toLocaleString('it-IT', this.options);

  user = null;
  chatId: number = -1;

  promptString: string = '';
  isLoading: boolean = false;
  messages: {
    fromAi: boolean;
    text: String;
    isAudioPlaying?: boolean;
    audioUrl?: String;
    date?: String;
  }[] = [];

  currentLessonIndex: number = -1;
  currentLessonMessageIndex: number = -1;
  currentLessonText: String = '';

  audioLesson = null;
  currentAudioMessage = null;
  isAudioPlaying = false;

  constructor(private neithServiceProvider: NeithServiceProviderService,
              private router: Router) {
    this.neithServiceProvider.getUser().subscribe(users => {
      this.user = users[0];
      if (!this.user.has_neith_gpt && !this.user.has_neith_gpt_demo) {
        if (this.user.has_neith_ai_active || this.user.has_neith_demo) {
          this.router.navigate(['/chat']);
        } else {
          this.router.navigate(['/login']);
        }
      } else {
        this.retrieveUserChat();
      }
    }, error => {
      this.router.navigate(['/login']);
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    if (this.isAudioPlaying) {
      return;
    }
    this.scrollToBottom();
  }

  retrieveUserChat() {
    this.neithServiceProvider.retrieveTeacherChat(this.user.id).subscribe((response) => {
      this.chatId = response['chat'];
      this.getMessages();
    });
  }

  getMessages() {
    this.neithServiceProvider.getTeacherChatMessages(this.user.id, this.chatId).subscribe((messages) => {
      messages.forEach(message => {
        this.messages.push({
          fromAi: message.role == 2,
          text: message.message,
          audioUrl: message.audioUrl != '' ? message.audio_url : null,
          date: new Date(message.created_datetime).toLocaleString('it-IT', this.options),
        });
      });
      this.messages.push({
        fromAi: true,
        text: "Ciao, sono Heru, il tuo insegnate virtuale e ti aiuterò a studiare Italiano, Storia, Matematica, Inglese e Geografia. Come posso aiutarti?"
      })
    });
  }

  sendPrompt() {
    if (!this.promptString) {
      return;
    }
    if (this.currentLessonText) {
      this.currentLessonIndex = this.currentLessonText.length;
      this.messages[this.messages.length - 1].text = this.currentLessonText;
    }
    this.messages.push({
      fromAi: false,
      text: this.promptString
    });
    this.isLoading = true;
    this.neithServiceProvider.sendTeacherPrompt(this.promptString, this.chatId).subscribe(response => {
      this.manageResponse(response);
    }, error => this.manageError());
    this.promptString = '';
  }

  manageResponse(response) {
    let text = response['text'];
    this.messages.push({
      fromAi: true,
      text: ``,
      audioUrl: response['audio_url']
    });
    this.isLoading = false;
    this.currentLessonText = text;
    this.currentLessonIndex = 0;
    this.currentLessonMessageIndex = this.messages.length - 1;
    this.startLessonDelayedText();
  }

  startLessonDelayedText() {
    if (this.currentLessonIndex < this.currentLessonText.length) {
      setTimeout(() => {
        this.messages[this.currentLessonMessageIndex].text += this.currentLessonText[this.currentLessonIndex] ? this.currentLessonText[this.currentLessonIndex] : '';
        this.currentLessonIndex++;
        this.startLessonDelayedText();
      }, 50);
    }
  }

  manageError() {
    this.messages.push({
      fromAi: true,
      text: 'Scusami, stavo giocando a scacchi con Siri. Puoi ripetere la domanda?',
    });
    this.isLoading = false;
  }

  listenToLesson(message) {
    if (this.currentAudioMessage) {
      this.stopAudio(this.currentAudioMessage);
    }
    this.audioLesson = new Audio();
    this.audioLesson.src = message.audioUrl;
    this.audioLesson.load();
    this.audioLesson.play();
    this.currentAudioMessage = message;
    this.isAudioPlaying = true;
    this.audioLesson.onended = () => {
      message.isAudioPlaying = false;
    };
    message.isAudioPlaying = true;
  }

  stopAudio(message) {
    if (!this.audioLesson) {
      return;
    }
    this.audioLesson.pause();
    this.audioLesson.currentTime = 0;
    message.isAudioPlaying = false;
    this.isAudioPlaying = false;
  }

  scrollToBottom() {
    try {
      this.scrollScene.nativeElement.scrollTop = this.scrollScene.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

}
