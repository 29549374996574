import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SocialAuthService} from 'angularx-social-login';
import {NeithServiceProviderService} from 'src/app/core/providers/neith-service-provider';
import {AuthService} from '../../_services/auth.service';
import {TokenStorageService} from '../../_services/token-storage.service';
import {FacebookLoginProvider} from 'angularx-social-login';
import {AIConfig} from 'src/app/core/models';
import {Subscription} from 'rxjs';
import {EventBusService} from '../../_shared/event-bus.service';
import {FormControl, Validators} from '@angular/forms';
import {BreakpointObserver} from '@angular/cdk/layout';
import {DemoIntroDialogComponent} from '../demo-intro-dialog/demo-intro-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

declare var AppleID;
declare var google;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showLoginAlert = false;
  showRegisterAlert = false;
  showRegisterErrorAlert = false;
  isRegisteringForDemo = false;

  firstname = new FormControl('');
  lastname = new FormControl('');
  email = new FormControl('', [Validators.required, Validators.email]);
  phone = new FormControl('');
  password = new FormControl('', [Validators.required]);
  privacy = new FormControl('');

  isLoggedIn = false;
  isLoginFailed = false;
  drivingSchool: any = {};
  drivingSchoolLanding = false;
  errorMessage = '';
  hasNeithAIActive: boolean = false;
  user: any = {};
  abTestVersion: String = '';
  aiConfig: AIConfig = {};
  requestSent = false;
  eventBusSub?: Subscription;
  chatDialog: MatDialogRef<any> = null;

  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private neithService: NeithServiceProviderService,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private eventBusService: EventBusService,
    private breakPointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    if (this.breakPointObserver.isMatched('(max-width: 840px)')) {
      this.showChatIntroVideo();
    }
    this.isLoggedIn = !!this.tokenStorage.getToken();

    if (this.isLoggedIn) {
      this.neithService.getUser().subscribe(
        data => {
          let user = data[0];
          this.tokenStorage.saveUser(user);
          // this.firstname = user.firstname;
          this.user = user;
          this.hasNeithAIActive = this.user.has_neith_ai_active;
          this.abTestVersion = this.user.ab_test_version;
          if (!this.abTestVersion) {
            this.neithService.getUserAbTestVersion(this.user.id).subscribe((response) => {
              this.abTestVersion = response['version'];
              if (this.hasNeithAIActive || this.user.has_neith_demo) {
                this.redirect();
              } else if (this.user.has_neith_gpt || this.user.has_neith_gpt_demo) {
                this.redirectNeithGPT();
              } else {
                this.errorNoNeith();
              }
            });
          } else {
            if (this.hasNeithAIActive || this.user.has_neith_demo) {
              this.redirect();
            } else if (this.user.has_neith_gpt || this.user.has_neith_gpt_demo) {
              this.redirectNeithGPT();
            } else {
              this.errorNoNeith();
            }
          }
        },
        err => {
          this.router.navigate(['/login']);
        }
      );
    } else {
      this.router.navigate(['/login']);
    }

    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.logout();
    });

  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.socialAuthService.authState.subscribe((user) => {
      let credentials = {
        facebookId: user.id,
        email: user.email,
        password: user.id,
        firstname: user.firstName,
        lastname: user.lastName
      };
      this.authService.loginFacebook(credentials).subscribe(
        data => {
          this.tokenStorage.saveToken(data.access_token);
          this.tokenStorage.saveRefreshToken(data.refresh_token);
        },
        err => {
          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
        },
        () => {
          this.getUser();
        }
      );
    });
  }

  public async signInWithApple() {
    try {
      AppleID.auth.init({
        clientId: 'com.bokapp.QuizPatente-.neith',
        scope: 'name email',
        redirectURI: 'https://insegnantevirtuale.guidaevai.com/login',
        state: 'init',
        usePopup: true
      });
      const data = await AppleID.auth.signIn();
      this.authService.loginApple(data.authorization.code).subscribe(
        data => {
          this.tokenStorage.saveToken(data.access_token);
          this.tokenStorage.saveRefreshToken(data.refresh_token);
        },
        err => {
          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
        },
        () => {
          this.getUser();
        }
      );
    } catch (err) {
      this.isLoginFailed = true;
    }
  }

  onSubmit(): void {
    if (this.email.invalid || this.password.invalid) {
      this.showLoginAlert = true;
      return;
    }

    let credentials = {'email': this.email.value, 'password': this.password.value};
    this.authService.login(credentials).subscribe(
      data => {
        this.tokenStorage.saveToken(data.access_token);
        this.tokenStorage.saveRefreshToken(data.refresh_token);
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
        this.showRegisterErrorAlert = false;
        this.showLoginAlert = false;
        this.showRegisterAlert = false;
      },
      () => {
        this.getUser();
      }
    );
  }

  register() {
    if (!this.privacy.value || this.firstname.invalid || this.lastname.invalid || this.email.invalid || this.password.invalid || this.phone.invalid) {
      this.showRegisterAlert = true;
      return;
    }
    let credentials = {
      'email': this.email.value,
      'password': this.password.value,
      'firstname': this.firstname.value,
      'surname': this.lastname.value,
      'phone': this.phone.value,
      'driving_school_neith': 4646
    };
    this.authService.register(credentials).subscribe((response) => {
      let heruBody = {
        'email': this.email.value,
        'firstname': this.firstname.value,
        'lastname': this.lastname.value,
        'phone': this.phone.value,
        'type': 'demo'
      };
      this.neithService.activateHeruDemo(heruBody).subscribe(() => {
        let body = {
          'email': this.email.value,
          'phone': this.phone.value,
          'firstname': this.firstname.value,
          'lastname': this.lastname.value,
          'type': 'heru',
          'date': new Date().toLocaleDateString()
        };
        this.neithService.saveDemoUserOnCsv(body).subscribe(result => {
          this.onSubmit();
        });
      });
    }, error => {
      this.showRegisterErrorAlert = true;
      this.showLoginAlert = false;
      this.showRegisterAlert = false;
    });
  }

  errorNoNeith() {
    if (this.user.driving_school_neith) {
      this.requestSent = true;
    }
    if (this.user.driving_school && this.user.driving_school.has_neith_enabled) {
      this.drivingSchool = this.user.driving_school;
      this.drivingSchoolLanding = true;
      this.neithService.getAIConfig(this.user.driving_school.id).subscribe((data) => {
        this.aiConfig = data[0];
      });
    } else {
      if (this.user.latitude > 0 && this.user.longitude > 0) {
        let geocoder = new google.maps.Geocoder();
        let latlng = new google.maps.LatLng(this.user.latitude, this.user.longitude);
        let request = {
          latLng: latlng
        };

        geocoder.geocode(request, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0] != null) {
              results[0].address_components.forEach(address_component => {
                if (address_component.types[0] == 'locality') {
                  this.neithService.getNeithDrivingSchools(address_component.long_name).subscribe(
                    drivingSchools => {
                      if (drivingSchools.length > 0) {
                        this.drivingSchool = drivingSchools[0];
                        this.drivingSchoolLanding = true;
                        this.neithService.getAIConfig(drivingSchools[0].id).subscribe((data) => {
                          this.aiConfig = data[0];
                        });
                      } else {
                        this.redirectLanding();
                      }
                    },
                    err => {
                      this.redirectLanding();
                    }
                  );
                }
              });
            } else {
              this.redirectLanding();
            }
          } else {
            this.redirectLanding();
          }
        });
      } else {
        this.redirectLanding();
      }
    }
  }

  redirectLanding() {
    window.location.href = 'https://guidaevai.com/insegnante-virtuale/';
  }

  redirect(): void {
    if (this.chatDialog !== null) {
      this.chatDialog.close();
    }
    this.router.navigate(['/chat']);
  }

  redirectNeithGPT() {
    if (this.chatDialog !== null) {
      this.chatDialog.close();
    }
    this.router.navigate(['/heru']);
  }


  private getUser() {
    this.neithService.getUser().subscribe(
      data => {
        this.user = data[0];
        this.tokenStorage.saveUser(data[0]);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.tokenStorage.getUser().then(user => {
          this.hasNeithAIActive = user.has_neith_ai_active;
          if (this.hasNeithAIActive || this.user.has_neith_demo) {
            this.redirect();
          } else if (this.user.has_neith_gpt || this.user.has_neith_gpt_demo) {
            this.redirectNeithGPT();
          } else {
            this.errorNoNeith();
          }
        });
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  sendRequest() {
    this.neithService.sendNeithRequest(this.user.id, this.drivingSchool.id).subscribe(
      data => {
        this.requestSent = true;
      },
      err => {

      }
    );
  }

  logout(): void {
    this.tokenStorage.signOut();
    this.isLoggedIn = false;
  }

  goToShop() {
    window.location.href = 'https://guidaevai.com/prodotto/pacchetto-neith-insegnante-virtuale/';
  }

  changeFormType(isRegisteringForDemo) {
    this.isRegisteringForDemo = isRegisteringForDemo;
    this.showLoginAlert = false;
    this.showRegisterAlert = false;
    this.showRegisterErrorAlert = false;
    this.isLoginFailed = false;
    if (this.isRegisteringForDemo) {
      this.firstname.setValidators([Validators.required]);
      this.lastname.setValidators([Validators.required]);
      this.privacy.setValidators([Validators.required]);
      this.phone.setValidators([Validators.required]);
    } else {
      this.firstname.clearValidators();
      this.lastname.clearValidators();
      this.privacy.clearValidators();
      this.phone.clearValidators();
    }
  }

  showChatIntroVideo() {
    this.chatDialog = this.dialog.open(DemoIntroDialogComponent, {
      panelClass: 'fullscreen-dialog'
    });
    this.chatDialog.afterClosed().subscribe(() => {
      this.changeFormType(true);
    });
  }
}
