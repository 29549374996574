import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './screens/login/login.component';
import { ChatComponent } from './screens/chat/chat.component';
import {NeithServiceProviderService} from './core/providers/neith-service-provider';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { ImagesDialogComponent } from './screens/images-dialog/images-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ArgumentsDialogComponent } from './screens/arguments-dialog/arguments-dialog.component';
import {MatListModule} from '@angular/material/list';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { FacebookLoginProvider } from 'angularx-social-login';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DemoDialogComponent } from './screens/demo-dialog/demo-dialog.component';
import { BuyDialogComponent } from './screens/buy-dialog/buy-dialog.component';
import { DemoIntroDialogComponent } from './screens/demo-intro-dialog/demo-intro-dialog.component';
import { SuperiorVideosDialogComponent } from './screens/superior-videos-dialog/superior-videos-dialog.component';
import { TeacherChatComponent } from './screens/teacher-chat/teacher-chat.component';
import { RegisterComponent } from './screens/register/register.component';
import { HeruDemoDialogComponent } from './screens/heru-demo-dialog/heru-demo-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChatComponent,
    ImagesDialogComponent,
    ArgumentsDialogComponent,
    DemoDialogComponent,
    BuyDialogComponent,
    DemoIntroDialogComponent,
    SuperiorVideosDialogComponent,
    TeacherChatComponent,
    RegisterComponent,
    HeruDemoDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    SocialLoginModule,
    TranslateModule.forRoot({
      loader:{
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    NeithServiceProviderService,
    authInterceptorProviders,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('203364297969521')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
