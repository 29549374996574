<div style="display: flex; flex-direction: column; height: 100%">
  <div class="container">
    <div class="section left">
      <div style="display: flex; flex-direction: column; margin: 1em; align-items: center; row-gap: 2em">
<!--        <img src="../../../assets/img/neith-logo-white.svg" alt="" width="150">-->
        <video style="width: 100%; height: 500px;" controls>
          <source src="https://app.guidaevai.com/media/neith_intro_ai.mp4" type="video/mp4">
        </video>
        <div style="color: #FFFFFF; font-weight: 500; font-size: 26px; margin-top: 1em">Il tuo insegnante virtuale</div>
        <p style="color: #FFFFFF; font-weight: 500; font-size: 20px;">
          <img src="../../../assets/img/icons/check-mark.svg" alt="" width="24"> Disponibile 24 ore su 24, 7 giorni su 7<br><br>
          <img src="../../../assets/img/icons/check-mark.svg" alt="" width="24"> Pronto a rispondere ad ogni tua domanda<br><br>
          <img src="../../../assets/img/icons/check-mark.svg" alt="" width="24"> Preparati all'esame teorico della
          patente in un tempo 5 volte inferiore alla media<br><br>
        </p>
        <button class="buy-button-left" (click)="changeFormType(true)">Prova Gratis</button>
      </div>
    </div>
    <div class="section">
      <div class="buy-banner" *ngIf="!drivingSchoolLanding">
        Non hai ancora un account? Registrati e prova gratis.
        <button class="buy-button" (click)="changeFormType(true)">Prova Gratis</button>
      </div>
      <div class="sub-section-top">
        <form *ngIf="!drivingSchoolLanding">
          <img src="../../../assets/img/neith-bg.svg" alt="Neith" height="150">
          <input type="text" placeholder="Nome" [formControl]="firstname" name="firstname" *ngIf="isRegisteringForDemo">
          <input type="text" placeholder="Cognome" [formControl]="lastname" name="lastname"
                 *ngIf="isRegisteringForDemo">
          <input type="email" placeholder="Email" required [formControl]="email" name="email">
          <input type="tel" placeholder="Cellulare" required [formControl]="phone" name="phone"  *ngIf="isRegisteringForDemo">
          <input type="password" placeholder="Password" required [formControl]="password" name="password">
          <div style="display: flex; justify-content: end" *ngIf="!isRegisteringForDemo">
            <a href="https://app.guidaevai.com/password_reset/" target="_blank"
               style="text-decoration: none;color: #642EFF;font-weight: 500;">Password dimenticata?</a>
          </div>
          <div style="display: flex; justify-content: end; cursor: pointer" *ngIf="isRegisteringForDemo"
               (click)="changeFormType(false)">
            <span style="text-decoration: none;color: #642EFF;font-weight: 500;">Già registrato? Fai il login</span>
          </div>
          <div *ngIf="isRegisteringForDemo" style="color: #1C1C1C;display: flex;align-items: center;column-gap: 12px;">
            <input type="checkbox" id="privacy" name="privacy" [formControl]="privacy">
            <label for="privacy">Ho preso visione dell'<a href="https://guidaevai.com/privacy-policy/"
                                                          style="text-decoration: none;color: #642EFF;">informativa</a>
              e
              presto il consenso al trattamento dei miei dati per
              la creazione dell’account</label><br>
          </div>
          <button type="submit" (click)="onSubmit()" *ngIf="!isRegisteringForDemo">Login</button>
          <button type="submit" (click)="register()" *ngIf="isRegisteringForDemo">Registrati</button>
          <div class="danger-alert" *ngIf="isLoginFailed">
            Email e/o password errati
          </div>
          <div class="danger-alert" *ngIf="showLoginAlert">
            Compila tutti i campi per effettuare il login
          </div>
          <div class="danger-alert" *ngIf="showRegisterAlert">
            Compila tutti i campi e accetta l'informativa per registrarti
          </div>
          <div class="danger-alert" *ngIf="showRegisterErrorAlert">
            Credenziali già esistenti
          </div>
          <div style="display: flex; justify-content: center; align-items: center; margin-top: 16px" *ngIf="!isRegisteringForDemo">
            <div style="width: 100%; height: 1px; background: #c9c9c9;"></div>
            <div style="width: 100%; display: flex; justify-content: center; color: #c9c9c9">Oppure</div>
            <div style="width: 100%; height: 1px; background: #c9c9c9;"></div>
          </div>
          <div class="other-logins-container" *ngIf="!isRegisteringForDemo">
            <div class="facebook" (click)="signInWithFB()"><img src="../../../assets/img/icons/facebook.svg" alt=""
                                                                width="24"/> Continua con
              Facebook
            </div>
            <div class="apple" (click)="signInWithApple()"><img src="../../../assets/img/icons/apple-logo-white.svg" alt=""
                                                                width="24"/> Accedi con Apple
            </div>
          </div>
        </form>
        <div *ngIf="isLoggedIn && drivingSchoolLanding" class="request-container">
          <img [src]="aiConfig && aiConfig.image ? aiConfig.image : '../../../assets/img/neith-bg.svg'"
               class="image-profile"
               [ngStyle]="aiConfig && aiConfig.image ? { 'object-fit': 'cover', 'object-position': 'top' } : ''"
               [alt]="aiConfig && aiConfig.name ? aiConfig.name : 'Neith'">
          <div *ngIf="!requestSent" style="display: flex; flex-direction: column; align-items: center;">
            <p class="introduction">Ciao {{ user.firstname }}, sono {{ aiConfig ? aiConfig.name : "Salvo" }}.<br>Per
              parlare
              con me, clicca su <br>"Richiedi Sblocco"</p>
            <br>
            <button type="submit" (click)="sendRequest()">
              Richiedi Sblocco
            </button>
          </div>
          <div *ngIf="requestSent">
            <p class="introduction">Ciao {{ user.firstname }}, sono {{ aiConfig ? aiConfig.name : "Salvo" }}.<br> A
              breve
              avrai maggiori informazioni su come sbloccare questa funzionalità</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
