<mat-toolbar class="custom-header">
  <div class="title-container">
    <div class="title">{{"Benvenuto nella tua Prova Gratuita" | translate}}</div>
  </div>
  <button mat-icon-button color="primary" (click)="dismiss()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<div class="container">
  <iframe class="ratio-inner" [src]="safeUrl" frameborder="0" webkitallowfullscreen="true"
          mozallowfullscreen="true" allow="fullscreen"></iframe>
</div>
