import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './screens/login/login.component';
import {ChatComponent} from './screens/chat/chat.component';
import {AuthGuard} from './_services/auth-guard';
import {TeacherChatComponent} from './screens/teacher-chat/teacher-chat.component';
import {RegisterComponent} from './screens/register/register.component';


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'login-heru', component: RegisterComponent},
  {path: 'chat', component: ChatComponent, canActivate: [AuthGuard]},
  {path: 'heru', component: TeacherChatComponent, canActivate: [AuthGuard]},
  {path: '', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
