<div style="width: 100%; height: 100%; display: flex; flex-direction: column;">
  <mat-toolbar class="custom-header">
    <img [src]="aiConfig && aiConfig.image ? aiConfig.image : '../../../assets/img/neith-bg.svg'"
         [ngStyle]="aiConfig && aiConfig.image ? { 'object-fit': 'cover', 'object-position': 'top' } : ''"
         [alt]="aiConfig && aiConfig.name ? aiConfig.name : 'Neith'">
    <div class="username-container">
      <div class="username">{{ aiConfig && aiConfig.name ? aiConfig.name : 'Salvo' }}</div>
      <div class="username-status" *ngIf="!isLoading">{{"Online" | translate}}</div>
      <div class="username-status" *ngIf="isLoading">{{"Sta scrivendo..." | translate}}</div>
    </div>
    <img [src]="aiConfig && aiConfig.logo_image ? aiConfig.logo_image : '../../../assets/img/neith-logo.png'"
         class="logo" alt="Neith">
  </mat-toolbar>
  <div class="pinned-message-container" *ngIf="hasNeithDemo && !hasNeithAIActive"
       style="background: #1C1C1C; color: #FFFFFF">
    <div class="pinned-message-content" style="margin-top: 0">
      <div
        class="pinned-message-content-title">{{"Questa è una Prova Gratuita, esplora tutte le funzionalità di Salvo, il tuo insegnante virtuale" | translate}}</div>
      <button class="custom-button action language demo"
              (click)="goToShop()">{{"Acquista" | translate}}</button>
    </div>
  </div>
  <div class="pinned-message-container" *ngIf="currentLessonTitle">
    <div class="pinned-message-header">{{"Lezione corrente" | translate}}</div>
    <div class="pinned-message-content">
      <div class="pinned-message-content-title">{{currentLessonTitle | translate}}</div>
      <button class="custom-button action language"
              (click)="scrollToLesson()">{{"Torna alla lezione" | translate}}</button>
    </div>
  </div>

  <div class="container">
    <div #scrollScene class="scene">
      <div *ngFor="let message of messages, let i = index">
        <div style="display: flex; justify-content: center; align-items: center; margin-top: 16px; margin-bottom: 16px;"
             *ngIf="(message.date && i == 0) || (message.date && message.date !== messages[i-1].date) && !message.isLanguageSelector">
          <div style="width: 100%; height: 1px; background: #c9c9c9;"></div>
          <div
            style="width: 100%; display: flex; justify-content: center; color: #c9c9c9">{{message.date == today ? "Oggi" : (message.date == yesterday ? "Ieri" : message.date) }}</div>
          <div style="width: 100%; height: 1px; background: #c9c9c9;"></div>
        </div>
        <!--        <div *ngIf="message.isLanguageSelector" style="width: 100%; height: 1px; background: #c9c9c9; margin-top: 16px; margin-bottom: 16px;"></div>-->
        <div class="message-container"
             [ngClass]="{'from-them': message.fromAi, 'from-me': !message.fromAi}">
          <img [src]="images[message.imageIndex].url" *ngIf="message.imageIndex >= 0 && images[message.imageIndex]"
               height="150" alt="">
          <img [src]="message.imageUrl" *ngIf="message.imageUrl"
               height="150" alt="">
          <br *ngIf="message.imageIndex >= 0">
          <br *ngIf="message.imageIndex >= 0">
          <p [ngClass]="{
          'from-them': message.fromAi,
          'from-me': !message.fromAi,
          'pink': (message.fromAi && aiConfig && aiConfig.colour_primary == '#FF86D9') || (!message.fromAi && aiConfig && aiConfig.colour_secondary == '#FF86D9'),
          'yellow': (message.fromAi && aiConfig && aiConfig.colour_primary == '#D9F300') || (!message.fromAi && aiConfig && aiConfig.colour_secondary == '#D9F300'),
          'orange': (message.fromAi && aiConfig && aiConfig.colour_primary == '#FF5222') || (!message.fromAi && aiConfig && aiConfig.colour_secondary == '#FF5222'),
          'purple': (message.fromAi && aiConfig && aiConfig.colour_primary == '#642EFF') || (!message.fromAi && aiConfig && aiConfig.colour_secondary == '#642EFF'),
          'green': (message.fromAi && aiConfig && aiConfig.colour_primary == '#00EB81') || (!message.fromAi && aiConfig && aiConfig.colour_secondary == '#00EB81'),
          'correct': (message.fromAi && message.isQuizText && message.answer),
          'incorrect': (message.fromAi && message.isQuizText && !message.answer)
        }" [innerHTML]="message.text" #lesson *ngIf="!message.hideMessage"></p>
          <button class="custom-button action" (click)="startTeaching(message)"
                  *ngIf="message.isArgumentName && !message.isTeaching">{{"Inizia la lezione" | translate}}
          </button>
          <div class="message-buttons-container">
            <div *ngIf="message.isLanguageSelector" class="manual-buttons-container">
              <button class="custom-button action language" (click)="setLanguage(0)"><img
                src='../../../assets/img/flags/italy.svg' alt="">{{"Italiano" | translate}}</button>
              <button class="custom-button action language" (click)="setLanguage(1)"><img
                src='../../../assets/img/flags/united-kingdom.svg' alt="">{{"Inglese" | translate}}</button>
              <button class="custom-button action language" (click)="setLanguage(2)"><img
                src='../../../assets/img/flags/spain.svg' alt="">{{"Spagnolo" | translate}}</button>
              <button class="custom-button action language" (click)="setLanguage(3)"><img
                src='../../../assets/img/flags/france.svg' alt="">{{"Francese" | translate}}</button>
              <button class="custom-button action language" (click)="setLanguage(4)"><img
                src='../../../assets/img/flags/germany.svg' alt="">{{"Tedesco" | translate}}</button>
              <button class="custom-button action language" (click)="setLanguage(5)"><img
                src='../../../assets/img/flags/china.svg' alt="">{{"Cinese" | translate}}</button>
              <button class="custom-button action language" (click)="setLanguage(6)"><img
                src='../../../assets/img/flags/russia.svg' alt="">{{"Russo" | translate}}</button>
              <button class="custom-button action language" (click)="setLanguage(7)"><img
                src='../../../assets/img/flags/romania.svg' alt="">{{"Rumeno" | translate}}</button>
              <button class="custom-button action language" (click)="setLanguage(8)"><img
                src='../../../assets/img/flags/ukraine.svg' alt="">{{"Ucraino" | translate}}</button>
            </div>
            <div *ngIf="message.isTeaching && message.fromAi" class="manual-buttons-container">
              <button class="custom-button action" (click)="stopLesson()"
                      *ngIf="isTeaching && currentLessonMessageIndex == i">{{"Interrompi lezione" | translate}}
              </button>
              <button class="custom-button action" (click)="nextParagraph(message)"
                      *ngIf="!isTeaching && currentLessonMessageIndex == i && manualIndex < manuals.length">
                {{"Paragrafo successivo" | translate}}
              </button>
            </div>
            <button class="custom-button action" (click)="listenToLesson(message)"
                    *ngIf="message.audioUrl && !message.isAudioPlaying">
              {{"Spiegazione vocale" | translate}}
            </button>
            <button class="custom-button action" (click)="stopAudio(message)" *ngIf="message.isAudioPlaying">
              {{"Interrompi spiegazione vocale" | translate}}
            </button>
            <button class="custom-button action" (click)="resume(message)"
                    *ngIf="!isTeaching && message.isError">{{"Riprendi" | translate}}
            </button>
            <button class="custom-button action" (click)="showQuizzes(i, message)"
                    *ngIf="(!isTeaching && message.manualName && !message.quizShown && message.hasQuizzes) || (message.isOldMessage && message.manualId > -1 && !message.isQuiz && !message.quizShown)">{{"Quiz" | translate}}
            </button>
            <button class="custom-button action" (click)="showQuizAnswers(i,true, message)"
                    *ngIf="(!isTeaching && message.manualName && !message.trueQuizShown && message.hasQuizzes) || (message.isOldMessage && message.manualId > -1 && !message.isQuiz && !message.trueQuizShown)">{{"Quiz veri" | translate}}
            </button>
            <button class="custom-button action" (click)="showQuizAnswers(i,false, message)"
                    *ngIf="(!isTeaching && message.manualName && !message.falseQuizShown && message.hasQuizzes) || (message.isOldMessage && message.manualId > -1 && !message.isQuiz && !message.falseQuizShown)">{{"Quiz falsi" | translate}}
            </button>
            <button class="custom-button action"
                    (click)="openImagesModal(message.manualId, false, message.isOldMessage)"
                    *ngIf="(!isTeaching && message.manualId && !message.quizId && !message.isVideo && filteredOtherImagesLength(message.manualId) > 0) || (message.isOldMessage && message.manualId > -1 && !message.isQuiz)">
              {{"Visualizza altre immagini" | translate}}
            </button>
            <div class="manual-buttons-container"
                 *ngIf="message.isVideo || (message.isOldMessage && message.manualId > -1 && !message.isQuiz)">
              <button class="custom-button action"
                      (click)="openImagesModal(message.manualId, true, message.isOldMessage)"
                      *ngIf="!isTeaching">
                {{"Video della lezione" | translate}}
              </button>
            </div>
            <div class="manual-buttons-container" *ngIf="message.isReview">
              <button class="custom-button action" (click)="reviewLesson(message)">{{"Ripassa" | translate}}
              </button>
            </div>
            <div class="manual-buttons-container" *ngIf="message.isQuiz">
              <button class="custom-button action"
                      [ngClass]="{'answer': message.userHasAnswered && message.userAnswer}"
                      [disabled]="message.userHasAnswered"
                      (click)="setUserAnswer(message, true, message.isOldMessage)">{{"Vero" | translate}}
              </button>
              <button class="custom-button action"
                      [ngClass]="{'answer': message.userHasAnswered && !message.userAnswer}"
                      [disabled]="message.userHasAnswered"
                      (click)="setUserAnswer(message, false, message.isOldMessage)">{{"Falso" | translate}}
              </button>
              <div class="quiz-result"
                   *ngIf="message.userHasAnswered"
                   [ngClass]="{'correct': message.answer === message.userAnswer, 'incorrect': message.answer !== message.userAnswer}">
                {{message.answer === message.userAnswer ? ("Risposta Esatta" | translate) : ("Risposta Errata" | translate)}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="from-them loading" [ngStyle]="{ 'background-color': aiConfig ? aiConfig.colour_primary : '' }"
         *ngIf="isLoading">
          <span class="dots-container">
            <span class="dot1"> </span>
            <span class="dot2"></span>
            <span class="dot3"></span>
          </span>
      </p>
    </div>
  </div>
  <div class="other-actions-container" *ngIf="false">
    <button class="custom-button action language"
            (click)="openImagesModal()" *ngIf="chatLicenseType == LICENSE_TYPE_B">{{"Immagini" | translate}}</button>
    <button class="custom-button action language"
            (click)="openVideosModal()" *ngIf="chatLicenseType !== LICENSE_TYPE_B">{{"Video" | translate}}</button>
    <button class="custom-button action language"
            (click)="openArgumentsModal()">{{"Capitoli" | translate}}</button>
  </div>
  <div class="pinned-message-container" *ngIf="hasNeithDemo && !hasNeithAIActive && neithDemoMessagesNumber < 5"
       style="background: #1C1C1C; color: #FFFFFF">
    <div class="pinned-message-content">
      <div
        class="pinned-message-content-title">{{"Messaggi di prova inviati" | translate}}{{" " + neithDemoMessagesNumber + "/5"}}</div>
    </div>
  </div>
  <div class="pinned-message-container" *ngIf="hasNeithDemo && !hasNeithAIActive && neithDemoMessagesNumber >= 5"
       style="background: #1C1C1C; color: #FFFFFF">
    <div class="pinned-message-content" style="margin-top: 0">
      <div
        class="pinned-message-content-title">{{"Per sbloccare questa funzionalità acquista" | translate}}</div>
      <button class="custom-button action language demo"
              (click)="goToShop()">{{"Acquista" | translate}}</button>
    </div>
  </div>
  <mat-toolbar class="message-box">
    <button class="custom-button action language"
            (click)="openImagesModal()" *ngIf="chatLicenseType == LICENSE_TYPE_B">{{"Immagini" | translate}}</button>
    <button class="custom-button action language" (click)="openVideosModal()"
            *ngIf="chatLicenseType !== LICENSE_TYPE_B">{{"Video" | translate}}</button>
    <button class="custom-button action language" (click)="openArgumentsModal()">{{"Capitoli" | translate}}</button>
    <button class="custom-button action language" (click)="openNeithGPT()">{{"Heru" | translate}}</button>
    <!--    <button mat-icon-button color="primary" (click)="showOtherActions = !showOtherActions">-->
    <!--      <mat-icon>add_box</mat-icon>-->
    <!--    </button>-->
    <!--    <input type="text" class="text-input" (keyup.enter)="sendPrompt()"-->
    <!--           [(ngModel)]="promptString" [placeholder]="'Chiedimi qualcosa' | translate"-->
    <!--           [disabled]="neithDemoMessagesNumber >= 5">-->
    <!--    <button mat-icon-button color="primary" (click)="sendPrompt()" [disabled]="neithDemoMessagesNumber >= 5">-->
    <!--      <mat-icon>send</mat-icon>-->
    <!--    </button>-->
  </mat-toolbar>
</div>
